import { replacementWords } from './Persona.type';

export enum FormType {
  'BLOG' = 'blog post',
  'PERSONABLOG' = 'persona blog post',
  'EMAIL' = 'email',
  'PERSONAEMAIL' = 'persona email',
  'SOCIAL' = 'social post',
  'PERSONA' = 'persona social post',
  'MARKETINGIDEAS' = 'marketing ideas',
  'PERSONAMARKETINGIDEAS' = 'persona marketing ideas',
  'CONTENT_IMPROVER' = 'content improver',
  'CONTENT_EXPANDER' = 'expand copy',
  'CONTENT_CONCISER' = 'simplify copy',
  'CONTENT_ENGAGER' = 'improve copy',
  'AUDIENCE_RETARGET' = 'audience retarget',
  'HERO_HEADER' = 'hero header',
  'PERSONA_HERO_HEADER' = 'persona hero header',
  'SUBHEADER' = 'sub header',
  'PERSONA_SUBHEADER' = 'persona website subheader',
  'FEATURE_DESCRIPTION' = 'feature description',
  'PERSONA_FEATURE_DESCRIPTION' = 'persona feature description',
  'CALL_TO_ACTION' = 'call to action',
  'PERSONA_CALL_TO_ACTION' = 'persona call to action',
  'PERSONA_TWITTER' = 'tweet',
  'PERSONA_INSTAGRAM' = 'instagram caption',
  'PERSONA_FACEBOOK' = 'facebook post',
  'PERSONA_LINKEDIN' = 'linkedin post',
  'PERSONA_NEWSLETTER' = 'persona newsletter',
  'PROMOTIONAL_NEWSLETTER' = 'promotional newsletter',
  'PRODUCT_UPDATE_NEWSLETTER' = 'product update newsletter',
  'EDUCATIONAL_NEWSLETTER' = 'educational newsletter',
  'ORGANIZATIONAL_NEWSLETTER' = 'organizational newsletter',
  'AUDIENCE_CHANGER' = 'audience changer',
  'FORMAT_CHANGER' = 'format changer',
  'TONE_CHANGER' = 'tone changer',
  'PAS_FRAMEWORK' = 'PAS framework',
  'SEASONAL' = 'seasonal holiday',
  'DISCOUNT' = 'discount promotion',
  'GIVEAWAY' = 'giveaway promotion',
  'LINKEDIN_COMPANY_BIO' = 'linkedin company bio',
  'YOUTUBE_CHANNEL_DESCRIPTION' = 'youtube channel description',
  'TIKTOK' = 'tik tok caption',
  'PRODUCT_DESCRIPTION' = 'product description',
  'META_ADS' = 'meta ads',
  'GOOGLE_ADS' = 'google ads',
  'VALUE_PROP' = 'value proposition',
  'YOUTUBE_VIDEO_DESCRIPTION' = 'youtube video description',
  'BRAND_MISSION' = 'brand mission',
  'YOUTUBE_VIDEO_SCRIPT' = 'youtube video script',
  'JOB_DESCRIPTION' = 'job description',
  'SHORT_VIDEO_SCRIPT' = 'short-form video script',
}

export enum InputLength {
  'SHORT' = 'short',
  'MEDIUM' = 'medium',
  'LONG' = 'long',
}

export enum ShortVideoLength {
  'FIFTEEN' = '15 seconds',
  'THIRTY' = '30 seconds',
  'FORTY-FIVE' = '45 seconds',
  'SIXTY' = '60 seconds',
}

export interface BlogInput {
  companyName: string;
  companyWebsite: string;
  intent: string;
  topics: string[];
  tone: string[];
  toneDegree: { [key: string]: string };
  targetDemographic: string[];
  length: InputLength;
  temperature: string;
  prevResponse?: string;
  advancedInputs?: boolean;
  campaignGoal?: string;
  emoji: string;
  hashtag: string;
}

export interface PersonaBlogInput {
  brand: string;
  brandDescription: string;
  brandPositioning: string;
  website: string;
  tone: string[];
  intent: string;
  toneDegree: { [key: string]: string };
  industry: string;
  painpoint: string;
  location: string[];
  occupation: string[];
  audience?: string;
  brandPic?: string;
  audPic?: string;
  age: string[];
  goals: string;
  barriers: string;
  triggers: string;
  product: string;
  productDescription: string;
  topics: string[];
  length: InputLength;
  temperature: string;
  prevResponse?: string;
  advancedInputs?: boolean;
  campaignGoal?: string;
  emoji: string;
  hashtag: string;
  brandAvoidArray?: string[];
  brandReplacementArray?: replacementWords[];
  brandSparinglyArray?: string[];
}

export interface EmailInput {
  companyName: string;
  companyWebsite: string;
  intent: string;
  topics: string[];
  tone: string[];
  toneDegree: { [key: string]: string };
  targetDemographic: string[];
  length: InputLength;
  temperature: string;
  prevResponse?: string;
  multipleOutputs: OutputQuantity;
  advancedInputs?: boolean;
  campaignGoal?: string;
  emoji: string;
  hashtag: string;
}

export interface PersonaEmailInput {
  brand: string;
  brandDescription: string;
  brandPositioning: string;
  website: string;
  tone: string[];
  intent: string;
  toneDegree: { [key: string]: string };
  industry: string;
  painpoint: string;
  location: string[];
  occupation: string[];
  audience?: string;
  brandPic?: string;
  audPic?: string;
  age: string[];
  goals: string;
  barriers: string;
  triggers: string;
  product: string;
  productDescription: string;
  topics: string[];
  length: InputLength;
  temperature: string;
  prevResponse?: string;
  multipleOutputs: OutputQuantity;
  advancedInputs?: boolean;
  campaignGoal?: string;
  emoji: string;
  hashtag: string;
  brandAvoidArray?: string[];
  brandReplacementArray?: replacementWords[];
  brandSparinglyArray?: string[];
}

export enum SocialPlatform {
  FACEBOOK = 'facebook',
  INSTAGRAM = 'instagram',
  TWITTER = 'twitter',
  LINKEDIN = 'linkedIn',
}

export enum AllPlatform {
  FACEBOOK = 'facebook',
  INSTAGRAM = 'instagram',
  TWITTER = 'twitter',
  LINKEDIN = 'linkedIn',
  EMAIL = 'email',
  BLOG = 'blog',
}

export interface SocialInput {
  platform: SocialPlatform;
  companyName: string;
  companyWebsite: string;
  intent: string;
  topics: string[];
  tone: string[];
  toneDegree: { [key: string]: string };
  targetDemographic: string[];
  length: InputLength;
  temperature: string;
  prevResponse?: string;
  multipleOutputs: OutputQuantity;
  advancedInputs?: boolean;
  campaignGoal?: string;
  emoji: string;
  hashtag: string;
}

export interface PersonaSocialInput {
  platform: SocialPlatform;
  brand: string;
  brandDescription: string;
  brandPositioning: string;
  website: string;
  tone: string[];
  intent: string;
  toneDegree: { [key: string]: string };
  industry: string;
  painpoint: string;
  topics: string[];
  location: string[];
  occupation: string[];
  audience?: string;
  brandPic?: string;
  audPic?: string;
  age: string[];
  goals: string;
  barriers: string;
  triggers: string;
  product: string;
  productDescription: string;
  emoji: string;
  hashtag: string;
  length: InputLength;
  temperature: string;
  prevResponse?: string;
  multipleOutputs: OutputQuantity;
  advancedInputs?: boolean;
  campaignGoal?: string;
  brandAvoidArray?: string[];
  brandReplacementArray?: replacementWords[];
  brandSparinglyArray?: string[];
}

export interface MarketingIdeasInput {
  intent: string;
  companyName: string;
  companyWebsite: string;
  prevResponse?: string;
  advancedInputs?: boolean;
}

export interface PersonaMarketingIdeasInput {
  intent: string;
  brand: string;
  brandDescription: string;
  brandPositioning: string;
  industry: string;
  age: string[];
  goals: string;
  barriers: string;
  triggers: string;
  topic: string;
  occupation: string[];
  audience?: string;
  brandPic?: string;
  audPic?: string;
  location: string[];
  painpoint: string;
  brandAvoidArray?: string[];
  brandReplacementArray?: replacementWords[];
  brandSparinglyArray?: string[];
}

export interface PersonaNewsletterInput {
  brand: string;
  brandDescription: string;
  brandPositioning: string;
  website: string;
  tone: string[];
  intent: string;
  toneDegree: { [key: string]: string };
  topics: string[];
  industry: string;
  painpoint: string;
  location: string[];
  occupation: string[];
  audience?: string;
  brandPic?: string;
  audPic?: string;
  age: string[];
  goals: string;
  barriers: string;
  triggers: string;
  newsletterType: string;
  newsletterTopics: string;
  lengthInput: string;
  temperature: string;
  prevResponse?: string;
  advancedInputs?: boolean;
  campaignGoal?: string;
  emoji: string;
  hashtag: string;
  brandAvoidArray?: string[];
  brandReplacementArray?: replacementWords[];
  brandSparinglyArray?: string[];
}

export interface JobDescriptionInput {
  brand: string;
  brandDescription: string;
  brandPositioning: string;
  website: string;
  tone: string[];
  intent: string;
  toneDegree: { [key: string]: string };
  topics: string[];
  industry: string;
  painpoint: string;
  location: string[];
  occupation: string[];
  audience?: string;
  brandPic?: string;
  audPic?: string;
  age: string[];
  goals: string;
  barriers: string;
  triggers: string;
  jobTitle: string;
  jobTopics: string;
  lengthInput: string;
  temperature: string;
  prevResponse?: string;
  advancedInputs?: boolean;
  campaignGoal?: string;
  emoji: string;
  hashtag: string;
  brandAvoidArray?: string[];
  brandReplacementArray?: replacementWords[];
  brandSparinglyArray?: string[];
}

export interface ContentImproverInput {
  intent: string;
  originalCopy: string;
  prevResponse?: string;
  advancedInputs?: boolean;
}

export interface AudienceRetargetInput {
  originalCopy: string;
  targetDemographic: string[];
  prevResponse?: string;
  advancedInputs?: boolean;
}

export interface HeroHeaderInput {
  brandDescription: string;
  tone: string[];
  targetDemographic: string[];
  advancedInputs?: boolean;
}

export interface PersonaHeroHeaderInput {
  brand: string;
  website: string;
  brandDescription: string;
  brandPositioning: string;
  industry: string;
  age: string[];
  goals: string;
  barriers: string;
  triggers: string;
  tone: string[];
  occupation: string[];
  audience?: string;
  brandPic?: string;
  audPic?: string;
  location: string[];
  painpoint: string;
  advancedInputs?: boolean;
  toneDegree: { [key: string]: string };
  temperature: string;
  brandAvoidArray?: string[];
  brandReplacementArray?: replacementWords[];
  brandSparinglyArray?: string[];
}

export interface SubheaderInput {
  brandDescription: string;
  tone: string[];
  targetDemographic: string[];
  advancedInputs?: boolean;
}

export interface PersonaSubheaderInput {
  brand: string;
  brandDescription: string;
  brandPositioning: string;
  website: string;
  tone: string[];
  industry: string;
  painpoint: string;
  location: string[];
  occupation: string[];
  audience?: string;
  brandPic?: string;
  audPic?: string;
  age: string[];
  goals: string;
  barriers: string;
  triggers: string;
  product: string;
  productDescription: string;
  advancedInputs?: boolean;
  toneDegree: { [key: string]: string };
  temperature: string;
  brandAvoidArray?: string[];
  brandReplacementArray?: replacementWords[];
  brandSparinglyArray?: string[];
}

export interface FeatureDescriptionInput {
  featureName: string;
  tone: string[];
  targetDemographic: string[];
  advancedInputs?: boolean;
}

export interface PersonaFeatureDescriptionInput {
  brand: string;
  website: string;
  industry: string;
  brandDescription: string;
  brandPositioning: string;
  featureName: string;
  tone: string[];
  painpoint: string;
  location: string[];
  occupation: string[];
  audience?: string;
  brandPic?: string;
  audPic?: string;
  age: string[];
  goals: string;
  barriers: string;
  triggers: string;
  advancedInputs?: boolean;
  prevResponse?: string;
  toneDegree: { [key: string]: string };
  temperature: string;
  brandAvoidArray?: string[];
  brandReplacementArray?: replacementWords[];
  brandSparinglyArray?: string[];
}

export interface CallToActionInput {
  brandDescription: string;
  tone: string[];
  targetDemographic: string[];
  advancedInputs?: boolean;
}

export interface PersonaCallToActionInput {
  brand: string;
  brandDescription: string;
  brandPositioning: string;
  website: string;
  tone: string[];
  industry: string;
  painpoint: string;
  location: string[];
  occupation: string[];
  audience?: string;
  brandPic?: string;
  audPic?: string;
  age: string[];
  goals: string;
  barriers: string;
  triggers: string;
  product: string;
  productDescription: string;
  advancedInputs?: boolean;
  prevResponse?: string;
  toneDegree: { [key: string]: string };
  temperature: string;
  brandAvoidArray?: string[];
  brandReplacementArray?: replacementWords[];
  brandSparinglyArray?: string[];
}

export interface AudienceChangerInput {
  painpoint: string;
  location: string[];
  occupation: string[];
  audience?: string;
  audPic?: string;
  age: string[];
  goals: string;
  barriers: string;
  triggers: string;
  text: string;
  advancedInputs?: boolean;
  prevResponse?: string;
  toneDegree: { [key: string]: string };
  temperature: string;
}

export interface FormatChangerInput {
  text: string;
  format: string;
  advancedInputs?: boolean;
  prevResponse?: string;
  toneDegree: { [key: string]: string };
  temperature: string;
}

export interface ToneChangerInput {
  text: string;
  tone: string[];
  advancedInputs?: boolean;
  prevResponse?: string;
  toneDegree: { [key: string]: string };
  temperature: string;
}

export interface PASFrameworkInput {
  brand: string;
  brandDescription: string;
  brandPositioning: string;
  website: string;
  tone: string[];
  industry: string;
  painpoint: string;
  location: string[];
  occupation: string[];
  audience?: string;
  brandPic?: string;
  audPic?: string;
  age: string[];
  goals: string;
  barriers: string;
  triggers: string;
  product: string;
  productDescription: string;
  prevResponse?: string;
  advancedInputs?: boolean;
  toneDegree: { [key: string]: string };
  temperature: string;
  brandAvoidArray?: string[];
  brandReplacementArray?: replacementWords[];
  brandSparinglyArray?: string[];
}

export interface SeasonalHolidayInput {
  brand: string;
  brandDescription: string;
  brandPositioning: string;
  website: string;
  tone: string[];
  industry: string;
  painpoint: string;
  location: string[];
  occupation: string[];
  audience?: string;
  brandPic?: string;
  audPic?: string;
  age: string[];
  goals: string;
  barriers: string;
  triggers: string;
  prevResponse?: string;
  seasonalTopic: string;
  toneDegree: { [key: string]: string };
  temperature: string;
  brandAvoidArray?: string[];
  brandReplacementArray?: replacementWords[];
  brandSparinglyArray?: string[];
}

export interface DiscountPromotionInput {
  brand: string;
  brandDescription: string;
  brandPositioning: string;
  website: string;
  tone: string[];
  industry: string;
  painpoint: string;
  location: string[];
  occupation: string[];
  audience?: string;
  brandPic?: string;
  audPic?: string;
  age: string[];
  goals: string;
  barriers: string;
  triggers: string;
  product: string;
  productDescription: string;
  duration: string;
  discountDetails: string;
  advancedInputs?: boolean;
  prevResponse?: string;
  format: string;
  toneDegree: { [key: string]: string };
  temperature: string;
  brandAvoidArray?: string[];
  brandReplacementArray?: replacementWords[];
  brandSparinglyArray?: string[];
}

export interface GiveAwayPromotionInput {
  brand: string;
  brandDescription: string;
  brandPositioning: string;
  website: string;
  tone: string[];
  industry: string;
  painpoint: string;
  location: string[];
  occupation: string[];
  audience?: string;
  brandPic?: string;
  audPic?: string;
  age: string[];
  goals: string;
  barriers: string;
  triggers: string;
  duration: string;
  entryDetails: string;
  prizeDetails: string;
  advancedInputs?: boolean;
  prevResponse?: string;
  toneDegree: { [key: string]: string };
  temperature: string;
  brandAvoidArray?: string[];
  brandReplacementArray?: replacementWords[];
  brandSparinglyArray?: string[];
}

export interface LinkedInCompanyBioInput {
  brand: string;
  brandDescription: string;
  brandPositioning: string;
  website: string;
  tone: string[];
  industry: string;
  painpoint: string;
  location: string[];
  occupation: string[];
  audience?: string;
  brandPic?: string;
  audPic?: string;
  age: string[];
  goals: string;
  barriers: string;
  triggers: string;
  topics: string[];
  emoji: string;
  hashtag: string;
  advancedInputs?: boolean;
  prevResponse?: string;
  toneDegree: { [key: string]: string };
  temperature: string;
  brandAvoidArray?: string[];
  brandReplacementArray?: replacementWords[];
  brandSparinglyArray?: string[];
}

export interface YoutubeChannelDescriptionInput {
  brand: string;
  brandDescription: string;
  brandPositioning: string;
  website: string;
  tone: string[];
  industry: string;
  painpoint: string;
  location: string[];
  occupation: string[];
  audience?: string;
  brandPic?: string;
  audPic?: string;
  age: string[];
  goals: string;
  barriers: string;
  triggers: string;
  channelName: string;
  channelDescription: string;
  advancedInputs?: boolean;
  prevResponse?: string;
  toneDegree: { [key: string]: string };
  temperature: string;
  brandAvoidArray?: string[];
  brandReplacementArray?: replacementWords[];
  brandSparinglyArray?: string[];
}

export interface TikTokCaptionInput {
  brand: string;
  brandDescription: string;
  brandPositioning: string;
  website: string;
  tone: string[];
  intent: string;
  toneDegree: { [key: string]: string };
  industry: string;
  painpoint: string;
  topics: string[];
  location: string[];
  occupation: string[];
  audience?: string;
  brandPic?: string;
  audPic?: string;
  videoDescription: string;
  age: string[];
  goals: string;
  barriers: string;
  triggers: string;
  emoji: string;
  hashtag: string;
  length: InputLength;
  temperature: string;
  prevResponse?: string;
  multipleOutputs: OutputQuantity;
  advancedInputs?: boolean;
  campaignGoal?: string;
  brandAvoidArray?: string[];
  brandReplacementArray?: replacementWords[];
  brandSparinglyArray?: string[];
}

export interface ProductDescriptionInput {
  brand: string;
  brandDescription: string;
  brandPositioning: string;
  website: string;
  tone: string[];
  industry: string;
  painpoint: string;
  location: string[];
  occupation: string[];
  audience?: string;
  brandPic?: string;
  audPic?: string;
  age: string[];
  goals: string;
  barriers: string;
  triggers: string;
  product: string;
  productDescription: string;
  extraProductDetails: string;
  advancedInputs?: boolean;
  prevResponse?: string;
  toneDegree: { [key: string]: string };
  temperature: string;
  brandAvoidArray?: string[];
  brandReplacementArray?: replacementWords[];
  brandSparinglyArray?: string[];
}

export interface MetaAdsInput {
  brand: string;
  brandDescription: string;
  brandPositioning: string;
  website: string;
  tone: string[];
  industry: string;
  painpoint: string;
  location: string[];
  occupation: string[];
  audience?: string;
  brandPic?: string;
  audPic?: string;
  age: string[];
  goals: string;
  barriers: string;
  triggers: string;
  keywords: string;
  description: string;
  lengthInput: string;
  advancedInputs?: boolean;
  multipleOutputs: OutputQuantity;
  prevResponse?: string;
  toneDegree: { [key: string]: string };
  temperature: string;
  brandAvoidArray?: string[];
  brandReplacementArray?: replacementWords[];
  brandSparinglyArray?: string[];
}

export interface GoogleAdsInput {
  brand: string;
  brandDescription: string;
  brandPositioning: string;
  website: string;
  tone: string[];
  industry: string;
  painpoint: string;
  location: string[];
  occupation: string[];
  audience?: string;
  brandPic?: string;
  audPic?: string;
  age: string[];
  goals: string;
  barriers: string;
  triggers: string;
  keywords: string;
  description: string;
  product: string;
  productDescription: string;
  advancedInputs?: boolean;
  prevResponse?: string;
  toneDegree: { [key: string]: string };
  temperature: string;
  brandAvoidArray?: string[];
  brandReplacementArray?: replacementWords[];
  brandSparinglyArray?: string[];
}

export interface ValuePropInput {
  brand: string;
  brandDescription: string;
  brandPositioning: string;
  website: string;
  tone: string[];
  industry: string;
  painpoint: string;
  location: string[];
  occupation: string[];
  audience?: string;
  brandPic?: string;
  audPic?: string;
  age: string[];
  goals: string;
  barriers: string;
  triggers: string;
  topics: string[];
  product: string;
  productDescription: string;
  advancedInputs?: boolean;
  prevResponse?: string;
  toneDegree: { [key: string]: string };
  temperature: string;
  emoji: string;
  hashtag: string;
  brandAvoidArray?: string[];
  brandReplacementArray?: replacementWords[];
  brandSparinglyArray?: string[];
}

export interface YoutubeVideoDescriptionInput {
  brand: string;
  brandDescription: string;
  brandPositioning: string;
  website: string;
  tone: string[];
  industry: string;
  painpoint: string;
  location: string[];
  occupation: string[];
  audience?: string;
  brandPic?: string;
  audPic?: string;
  age: string[];
  goals: string;
  barriers: string;
  triggers: string;
  videoTitle: string;
  videoDescription: string;
  callToAction: string;
  advancedInputs?: boolean;
  prevResponse?: string;
  toneDegree: { [key: string]: string };
  temperature: string;
  brandAvoidArray?: string[];
  brandReplacementArray?: replacementWords[];
  brandSparinglyArray?: string[];
}

export interface BrandMissionInput {
  brand: string;
  brandDescription: string;
  brandPositioning: string;
  website: string;
  tone: string[];
  industry: string;
  brandGoal: string;
  advancedInputs?: boolean;
  toneDegree: { [key: string]: string };
  topics: string[];
  prevResponse?: string;
  temperature: string;
  emoji: string;
  hashtag: string;
  brandAvoidArray?: string[];
  brandReplacementArray?: replacementWords[];
  brandSparinglyArray?: string[];
}

export interface YoutubeVideoScriptInput {
  brand: string;
  brandDescription: string;
  brandPositioning: string;
  website: string;
  tone: string[];
  industry: string;
  painpoint: string;
  location: string[];
  occupation: string[];
  audience?: string;
  brandPic?: string;
  audPic?: string;
  age: string[];
  goals: string;
  barriers: string;
  triggers: string;
  videoTopic: string;
  videoPurpose: string[];
  advancedInputs?: boolean;
  toneDegree: { [key: string]: string };
  topics: string[];
  prevResponse?: string;
  temperature: string;
  emoji: string;
  hashtag: string;
  brandAvoidArray?: string[];
  brandReplacementArray?: replacementWords[];
  brandSparinglyArray?: string[];
}

export interface ShortVideoScriptInput {
  brand: string;
  brandDescription: string;
  brandPositioning: string;
  website: string;
  tone: string[];
  industry: string;
  painpoint: string;
  location: string[];
  occupation: string[];
  audience?: string;
  brandPic?: string;
  audPic?: string;
  age: string[];
  goals: string;
  barriers: string;
  triggers: string;
  product: string;
  productDescription: string;
  videoTopic: string;
  videoTheme: string[];
  videoLength: ShortVideoLength;
  influencer: string;
  advancedInputs?: boolean;
  toneDegree: { [key: string]: string };
  topics: string[];
  prevResponse?: string;
  temperature: string;
  emoji: string;
  hashtag: string;
  brandAvoidArray?: string[];
  brandReplacementArray?: replacementWords[];
  brandSparinglyArray?: string[];
}

export type AllWebsiteInputs =
  | HeroHeaderInput
  | PersonaHeroHeaderInput
  | SubheaderInput
  | PersonaSubheaderInput
  | FeatureDescriptionInput
  | PersonaFeatureDescriptionInput
  | CallToActionInput
  | PersonaCallToActionInput;

export interface GeneratedResponse {
  text: string;
  id?: string;
  reasoning?: string;
  canKeepGoing: boolean;
}

export interface LikedResponse {
  responseId: string;
  response: string;
}

export interface ContentResponse {
  uid: string;
  time: number;
  type: string;
  response: string;
  platform?: string;
  brand?: string;
  brandPic?: string;
  audPic?: string;
  occupation?: string[];
  audience?: string;
  liked?: boolean;
  id: string;
  advancedInputs?: boolean;
  age?: string[];
  brandDescription?: string;
  emoji?: boolean;
  hashtag?: boolean;
  industry?: string;
  intent?: string;
  length?: string;
  location?: string[];
  multipleOutputs?: string;
  painpoint?: string;
  product?: string;
  productDescription?: string;
  temperature?: number;
  tone?: string[];
  toneDegree?: string[];
  topics?: string[];
  responseIndex?: number;
  superChatTitle?: string;
  TID?: string;
  assistantID?: string;
  prompt?: any;
  folderID?: string;
}

export interface ContentReasoningInput {
  id: string;
}

export interface FolderContent {
  id: string;
  name: string;
  parentID: string | null;
  path: string;
  uid: string;
  time: number;
  lastEdit: number;
}

export enum OutputQuantity {
  SINGLE = 'single',
  MULTIPLE = 'multiple',
}

export interface TrendingPersonaInput {
  brand: string;
  audience: string;
  platform: SocialPlatform;
}

export interface WordLimitInput {
  wordCount: number;
}
