/* eslint-disable react/jsx-key */
import React from 'react';
import { FC } from 'react';
import Image from 'next/image';
import styles from './NavSearchStyle.module.css';
import { useRouter } from 'next/router';
import { useAuthContext } from '../../../contexts/AuthContext';

type productType = {
  name: string;
  link: string;
  image: string;
};

type searchProps = {
  products: productType[];
};

export const SearchBar: FC<searchProps> = (props) => {
  const [searchVal, setSearchVal] = React.useState('');
  const router = useRouter();

  const handleInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchVal(e.target.value);
  };

  const handleClearBtn = () => {
    setSearchVal('');
  };

  const navigateToNewRoute = async (url: string) => {
    // Check if the URL should trigger a page reload
    const shouldReloadPage = url.includes('newsletter');

    // Use router.push() for normal navigation
    router.push(url);

    // Reload the page if necessary
    if (shouldReloadPage) {
      await router.push(url);
      window.location.reload();
    }
  };

  const filteredProducts = props.products.filter((product) => {
    return product.name.toLowerCase().includes(searchVal.toLowerCase());
  });

  return (
    <div className={styles['container']}>
      <div className={styles['container2']}>
        <input
          onChange={handleInput}
          value={searchVal}
          type="text"
          name="product-search"
          id="product-search"
          placeholder="Search Templates"
          className={styles['product-search']}
        />
        {/* {searchVal === '' && (
             <div className={styles['search_placeholder']}>
             <Image
               src='/icons/SearchBlack.svg'
               alt="Close"
               layout="fixed"
               objectFit="contain"
               height={14}
               width={14}
             /></div>
             )}
    {searchVal !== '' &&(
                  <div className={styles['search_placeholder2']}>
                  <Image
                    src='/icons/SearchBlack.svg'
                    alt="Close"
                    layout="fixed"
                    objectFit="contain"
                    height={14}
                    width={14}
                  /></div>
    )} */}

        {searchVal !== '' && (
          <button onClick={handleClearBtn} className={styles['clear-btn']}>
            <i className={styles['fas fa-times']}>
              <Image
                src="/icons/Close X.svg"
                alt="Close"
                layout="fixed"
                objectFit="contain"
                height={17}
                width={17}
              />
            </i>
          </button>
        )}
      </div>

      {searchVal !== '' && (
        <div className={styles['results-wrap']}>
          <ul className={styles['list-ul']}>
            {filteredProducts.map((product) => {
              return (
                <div className={styles['flex']}>
                  <li key={product.name} className={styles['list-item']}>
                    <Image
                      src={product.image}
                      alt="Close"
                      layout="fixed"
                      objectFit="contain"
                      height={14}
                      width={14}
                    />
                    <a
                      onClick={() => {
                        navigateToNewRoute(product.link);
                      }}
                      className={styles['list-name']}
                    >
                      {product.name}
                    </a>
                  </li>
                </div>
              );
            })}
          </ul>
        </div>
      )}
    </div>
  );
};

export const NavSearch = () => {
  const { userData } = useAuthContext();
  const products = [
    {
      name: 'Audience Finder',
      link: '/personaAutomation',
      image: '/icons/SearchBlack.svg',
    },
    {
      name: 'Facebook Post',
      link: '/editor/content?formType=facebook%20post',
      image: '/icons/Facebook.svg',
    },
    {
      name: 'Instagram Caption',
      link: '/editor/content?formType=instagram%20caption',
      image: '/icons/Instagram.svg',
    },
    {
      name: 'Tweet (X)',
      link: '/editor/content?formType=tweet',
      image: '/icons/Twitter.svg',
    },
    {
      name: 'LinkedIn Post',
      image: '/icons/LinkedIn.svg',
      link: '/editor/content?formType=linkedin%20post',
    },
    {
      name: 'Email',
      image: '/icons/Email_template.svg',
      link: '/editor/content?formType=persona%20email',
    },
    {
      name: 'Short Blog',
      image: '/icons/ShortBlog_template.svg',
      link: '/editor/content?formType=persona%20blog%20post',
    },
    {
      name: 'Website Header',
      image: '/icons/Star.svg',
      link: '/editor/website?formType=persona%20hero%20header',
    },
    {
      name: 'Website Subheader',
      image: '/icons/Menu-left.svg',
      link: '/editor/website?formType=persona%20website%20subheader',
    },
    {
      name: 'Feature Description',
      image: '/icons/Configure.svg',
      link: '/editor/website?formType=persona%20feature%20description',
    },
    {
      name: 'Call to Action',
      image: '/icons/Geo Locate.svg',
      link: '/editor/website?formType=persona%20call%20to%20action',
    },
    {
      name: 'Newsletter',
      image: '/icons/Bullhorn.svg',
      link: '/editor/content?formType=persona%20newsletter',
    },
    {
      name: 'Promotional Newsletter',
      image: '/icons/Bullhorn.svg',
      link: '/editor/content?formType=promotional%20newsletter',
    },
    {
      name: 'Product Update Newsletter',
      image: '/icons/Bullhorn.svg',
      link: '/editor/content?formType=product%20update%20newsletter',
    },
    {
      name: 'Educational Newsletter',
      image: '/icons/Bullhorn.svg',
      link: '/editor/content?formType=educational%20newsletter',
    },
    {
      name: 'Organizational Newsletter',
      image: '/icons/Bullhorn.svg',
      link: '/editor/content?formType=organizational%20newsletter',
    },
    {
      name: 'Audience Changer',
      image: '/icons/audienceChanger.svg',
      link: '/editor/toolkit?formType=audience%20changer',
    },
    {
      name: 'Format Changer',
      image: '/icons/formatChanger.svg',
      link: '/editor/toolkit?formType=format%20changer',
    },
    {
      name: 'Tone Changer',
      image: '/icons/toneChanger.svg',
      link: '/editor/toolkit?formType=tone%20changer',
    },
    {
      name: 'Seasonal/Holiday Promotion',
      image: '/icons/Tropical Drinks.svg',
      link: '/editor/content?formType=seasonal%20holiday',
    },
    {
      name: 'Discount Promotion',
      image: '/icons/Dollar.svg',
      link: '/editor/content?formType=discount%20promotion',
    },
    {
      name: 'Giveaway Promotion',
      image: '/icons/Ticket.svg',
      link: '/editor/content?formType=giveaway%20promotion',
    },
    {
      name: 'LinkedIn Company Bio',
      image: '/icons/LinkedIn.svg',
      link: '/editor/content?formType=linkedin%20company%20bio',
    },
    {
      name: 'YouTube Channel Description',
      image: '/icons/YouTube.svg',
      link: '/editor/content?formType=youtube%20channel%20description',
    },
    {
      name: 'YouTube Video Description',
      image: '/icons/YouTube.svg',
      link: '/editor/content?formType=youtube%20video%20description',
    },
    {
      name: 'YouTube Video Script',
      image: '/icons/YouTube.svg',
      link: '/editor/content?formType=youtube%20video%20script',
    },
    {
      name: 'Short-Form Video Script',
      image: '/icons/Scroll.svg',
      link: '/editor/content?formType=short-form%20video%20script',
    },
    {
      name: 'TikTok Caption',
      image: '/icons/Tiktok.svg',
      link: '/editor/content?formType=tik%20tok%20caption',
    },
    {
      name: 'Product Description',
      image: '/icons/Bike.svg',
      link: '/editor/toolkit?formType=product%20description',
    },
    {
      name: 'Value Proposition',
      image: '/icons/Room Service Help.svg',
      link: '/editor/toolkit?formType=value%20proposition',
    },
    {
      name: 'Campaign Brainstorm',
      image: '/icons/Weather Thunderstorms.svg',
      link: '/editor/brainstorm?formType=persona%20marketing%20ideas',
    },
    {
      name: 'Improve Copy',
      image: '/icons/Spaceship.svg',
      link: '/editor/toolkit?formType=improve%20copy',
    },
    {
      name: 'Simplify Copy',
      image: '/icons/Target.svg',
      link: '/editor/toolkit?formType=simplify%20copy',
    },
    {
      name: 'Expand Copy',
      image: '/icons/Full Screen Resize Expand.svg',
      link: '/editor/toolkit?formType=expand%20copy',
    },
    {
      name: 'Brand Mission',
      image: '/icons/Bio Fingerprint.svg',
      link: '/editor/toolkit?formType=brand%20mission',
    },
    {
      name: 'Job Description',
      image: '/icons/Bio Fingerprint.svg',
      link: '/editor/content?formType=job%20description',
    },
    {
      name: 'Meta Ads',
      image: '/icons/Star.svg',
      link: '/editor/content?formType=meta%20ads',
    },
    {
      name: 'Google Ads',
      image: '/icons/Google.svg',
      link: '/editor/content?formType=google%20ads',
    },
  ];

  const filteredProducts =
    userData?.featureLimitAccess && userData?.allowedFeatures
      ? userData.limitFeatureName !== ''
        ? products.filter((product) =>
            userData.allowedFeatures?.includes(product.name)
          )
        : products
      : products;

  return (
    <div>
      <SearchBar products={filteredProducts} />
    </div>
  );
};
