/* eslint-disable @typescript-eslint/no-unsafe-member-access */
import Link from 'next/link';
import { useRouter } from 'next/router';
import { FC, ReactNode, useEffect, useState } from 'react';
import { FormType } from '../../../types/FormTypes';
import { HomeMinimalSvg } from '../../dynamicImages/HomeMinimalSvg';
import { SmileEmojiSvg } from '../../dynamicImages/SmileEmojiSvg';
import { WorldNetSvg } from '../../dynamicImages/WorldNetSvg';
import { SimpleTag } from '../badge/SimpleTag';
import Image from 'next/image';
import { useSettingsContext } from '../../../contexts/SettingsContext';
import styles from './SideNavbar.module.css';
import { SimpleBadge } from '../badge/SimpleBadge';
import { SmallerSimpleBadge } from '../badge/SmallerSimpleBadge';
import { TwitterSvg } from '../../dynamicImages/TwitterSvg';
import { InstagramSvg } from '../../dynamicImages/InstagramSvg';
import { FacebookSvg } from '../../dynamicImages/FacebookSvg';
import { LinkedInSvg } from '../../dynamicImages/LinkedInSvg';
import { EmailSvg } from '../../dynamicImages/EmailSvg';
import { BlogSvg } from '../../dynamicImages/BlogSvg';
import { WebsiteSubheaderSvg } from '../../dynamicImages/WebsiteSubheaderSvg';
import { WebsiteHeaderSvg } from '../../dynamicImages/WebsiteHeaderSvg';
import { FeatureDescriptionSvg } from '../../dynamicImages/FeatureDescriptionSvg';
import { CallToActionSvg } from '../../dynamicImages/CallToActionSvg';
import { CampaignBrainstormSvg } from '../../dynamicImages/CampaignBrainstormSvg';
import { ContentEngagerSvg } from '../../dynamicImages/ContentEngagerSvg';
import { ContentConciserSvg } from '../../dynamicImages/ContentConciserSvg';
import { ContentExpanderSvg } from '../../dynamicImages/ContentExpanderSvg';
import { ContentExpanderForm } from '../../pages/editorPage/forms/contentImprover/ContentExpanderForm';
import { useAuthContext } from '../../../contexts/AuthContext';
import { BasicPopup, BasicPopup2 } from '../popups/BasicPopup';
import { BasicButton } from '../buttons/BasicButton';
import { useFormContext } from '../../../contexts/FormContext';
import { UserData } from '../../../types/User.type';
import { SubscriptionType } from '../../../types/Stripe.type';
import { ShowMoreSvg } from '../../dynamicImages/ShowMoreSvg';
import { NewBlogSvg } from '../../dynamicImages/NewBlogSvg';
import { SearchSvg } from '../../dynamicImages/SearchSvg';
import { AudienceChangerSvg } from '../../dynamicImages/AudienceChangerSvg';
import { FormatChangerSvg } from '../../dynamicImages/FormatChangerSvg';
import { ToneChangerSvg } from '../../dynamicImages/ToneChangerSvg';
import { NewsletterSvg } from '../../dynamicImages/NewsletterSvg';
import { NavSearch } from '../searchbar/NavSearch';
import { v1, v4, v5, v3 } from 'uuid';
import Intercom from 'intercom-client';
import crypto from 'crypto';
import { TemplatesSVG } from '../../dynamicImages/TemplatesSVG';
import { PersonasSVG } from '../../dynamicImages/PersonasSVG';
import { CampaignsSVG } from '../../dynamicImages/CampaignsSVG';
import { SuperChatSVG } from '../../dynamicImages/SuperChatSVG';
import axios from 'axios';
import { ContentSVG } from '../../dynamicImages/ContentSVG';
import mixpanel from 'mixpanel-browser';
import { CompassSvg } from '../../dynamicImages/CompassSvg';

interface Window {
  intercomSettings: {
    app_id: string;
  };
}

declare global {
  interface Window {
    intercomSettings: { app_id: string };
    Intercom: unknown;
    attachEvent: unknown;
    tolt_referral: string | null;
    tolt: {
      signup: (email: string | null) => void;
    };
  }
}

interface CustomWindow extends Window {
  Intercom: (...args: any[]) => void;
  addEventListener?: typeof window.addEventListener;
  attachEvent?: (
    type: string,
    listener: EventListenerOrEventListenerObject
  ) => void;
}

type SidebarOptionHeader = {
  isSelected: boolean;
  text?: string;
  href: string;
  DynamicIcon?: ReactNode;
  simpleBadge?: ReactNode;
  nameless?: boolean;
};

const SidebarOptionHeader: FC<SidebarOptionHeader> = ({
  isSelected,
  text,
  href,
  DynamicIcon,
  simpleBadge,
  nameless,
}) => {
  return (
    <div
      className={styles['sidebar-option']}
      style={{
        backgroundColor: isSelected
          ? 'var(--ea-selected-gray)'
          : 'var(--ea-white-gray)',
        borderRadius: isSelected ? '5px' : '0px',
        width: isSelected ? (nameless ? '100%' : '85%') : '100%',
        position: isSelected ? 'sticky' : 'static',
        paddingLeft: isSelected
          ? nameless
            ? '9px'
            : '16px'
          : nameless
          ? '9px'
          : '30px',
        marginLeft: nameless ? '10px' : '0px',
        marginRight: nameless ? '10px' : '0px',
        display: isSelected ? 'inline-flex' : '',
        left: isSelected ? (nameless ? '0px' : '13px') : '0px',
        borderColor: 'var(--ea-white-gray)',
        paddingTop: nameless ? '9px' : '0px',
        paddingBottom: nameless ? '9px' : '0px',
      }}
    >
      <Link href={href}>
        <a>{DynamicIcon}</a>
      </Link>
      <Link href={href}>
        <a
          style={{
            color: isSelected ? 'var(--ea-dark)' : 'var(--ea-beige)',
          }}
        >
          {text && <h2 className={styles['header']}>{text}</h2>}
        </a>
      </Link>
      {simpleBadge}
    </div>
  );
};

type SidebarOptionType = {
  isSelected: boolean;
  text: string;
  href: string;
};

const SidebarOption: FC<SidebarOptionType> = ({ isSelected, text, href }) => {
  return (
    <Link href={href}>
      <a
        className={styles['sidebar-option']}
        style={{
          backgroundColor: isSelected
            ? 'var(--ea-selected-gray)'
            : 'var(--ea-white-gray)',
          color: isSelected ? 'var(--ea-black)' : 'var(--ea-black)',
        }}
      >
        {text}
      </a>
    </Link>
  );
};

export const SideNavbar = ({
  children,
}: {
  children: ReactNode;
}): JSX.Element => {
  const router = useRouter();
  const { numInvitesRemaining } = useSettingsContext();
  const { authUser } = useAuthContext();
  const [isLoading, setIsLoading] = useState(false);
  const [isDisplayed, setIsDisplayed] = useState(false);
  const [showTrialEndScreen, setShowTrialEndScreen] = useState(false);
  const [showContentOptions, setShowContentOptions] = useState(true);
  const [showWebsiteOptions, setShowWebsiteOptions] = useState(true);
  const [showBrainstormOptions, setShowBrainstormOptions] = useState(true);
  const [showToolkitOptions, setShowToolkitOptions] = useState(true);
  const [showTrialBar, setShowTrialBar] = useState(false);
  const [trialDays, setTrialDays] = useState(0);
  const [limitMet, setLimitMet] = useState(false);
  const [tier3Met, setTier3Met] = useState(false);
  const [tier2Met, setTier2Met] = useState(false);
  const [tier1Met, setTier1Met] = useState(false);
  const [hideTitle, setHideTitle] = useState(false);
  const [appSumoLimit, setAppSumoLimitMet] = useState(false);
  const [unsubscribed, setUnsubscribed] = useState(false);
  const [normalSideNav, setNormalSideNav] = useState(true);
  const [superChatSideNav, setSuperChatSideNav] = useState(false);
  const [showOnboardingLoadScreen, setShowOnboardingLoadScreen] =
    useState(false);
  const { teamMembers } = useSettingsContext();
  const [showSidebarInMobile, setShowSidebarInMobile] =
    useState<boolean>(false);
  const [isInDesktopMode, setIsInDesktopMode] = useState<boolean>(false);
  const [selectedSidebarOption, setSelectedSidebarOption] = useState<
    string | undefined
  >(undefined);
  const { userData, signOutUser } = useAuthContext();
  const sumOfWordLimits: number = teamMembers.reduce(
    (sum, member) => sum + member.wordLimit,
    0
  );

  const secretKey = process.env.INTERCOM_API_KEY
    ? process.env.INTERCOM_API_KEY
    : ''; // secret key (keep safe!)
  const userIdentifier = userData?.email ? userData?.email : ''; // user's email address
  const hash = crypto
    .createHmac('sha256', secretKey)
    .update(userIdentifier)
    .digest('hex');

  useEffect(() => {
    const subscriptionType = userData?.stripe?.subscriptionType;
    const subscriptionLength = userData?.stripe?.subscriptionLength;

    if (subscriptionLength === 'appsumo') {
      let limitMet = false;
      if (subscriptionType === 'TIER1' && sumOfWordLimits > 15000) {
        limitMet = true;
      } else if (subscriptionType === 'TIER2' && sumOfWordLimits > 75000) {
        limitMet = true;
      } else if (subscriptionType === 'TIER3' && sumOfWordLimits > 300000) {
        limitMet = true;
      }
      setAppSumoLimitMet(limitMet);
    }
  }, [userData, sumOfWordLimits]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        await axios.post('/api/user/checkWordCountRefresh');
        // Other logic after the API call
      } catch (error) {
        // Handle error
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    mixpanel.identify(authUser?.uid);
    mixpanel.people.set_once({
      displayName: authUser?.displayName,
      email: authUser?.email,
      $email: authUser?.email,
      $name: authUser?.displayName,
      $distinct_id: authUser?.uid,
    });
    mixpanel.track('Sign in');
  }, []);

  useEffect(() => {
    if (userData?.stripe?.subscriptionType == SubscriptionType.FREE_TRIAL) {
      setShowTrialBar(true);
    }
  }, [userData]);

  useEffect(() => {
    if (userData?.finishedOnboarding === false) {
      setShowOnboardingLoadScreen(true);
    }
  }, [userData]);

  useEffect(() => {
    if (
      userData?.trialOver === true &&
      userData.subscribed === false &&
      userData?.stripe?.subscriptionLength === 'free trial'
    ) {
      setShowTrialEndScreen(true);
    }
  }, [userData]);

  useEffect(() => {
    if (userData?.subscribed === false && userData?.freeTrial === false) {
      setUnsubscribed(true);
    }
  }, [userData]);

  useEffect(() => {
    if (userData?.trialOver === true && userData.subscribed === false) {
      setShowTrialEndScreen(true);
    }
  }, [userData]);

  useEffect(() => {
    if (userData?.freeTrial === true && userData.wordLimit) {
      if (userData.wordLimit > 15000) {
        setLimitMet(true);
      }
    } else {
      if (
        userData?.stripe?.subscriptionType === SubscriptionType.LIMITED &&
        userData?.wordLimit
      ) {
        if (userData?.wordLimit > 15000) {
          setLimitMet(true);
        }
      }
    }
  }, [userData]);

  useEffect(() => {
    if (userData?.stripe?.subscriptionType === SubscriptionType.TIER3) {
      if (sumOfWordLimits > 300000) {
        setTier3Met(true);
      }
    }
  }, [userData, sumOfWordLimits]);

  useEffect(() => {
    if (userData?.stripe?.subscriptionType === SubscriptionType.TIER2) {
      if (sumOfWordLimits > 75000) {
        setTier2Met(true);
      }
    }
  }, [userData, sumOfWordLimits]);

  useEffect(() => {
    if (userData?.stripe?.subscriptionType === SubscriptionType.TIER1) {
      if (sumOfWordLimits > 15000) {
        setTier1Met(true);
      }
    }
  }, [userData, sumOfWordLimits]);

  useEffect(() => {
    if (userData?.trialDays) {
      if (userData.trialDays) {
        setTrialDays(userData?.trialDays);
      }
    }
  }, [userData]);

  useEffect(() => {
    // Set intercomSettings object
    (window as any).intercomSettings = {
      api_base: 'https://api-iam.intercom.io',
      app_id: 'xz3rcybk',
      name: userData?.displayName ? userData?.displayName : '', // Full name
      email: userData?.email ? userData?.email : '', // Email address
      created_at: userData?.startDate, // Signup date as a Unix timestamp
      user_hash: hash,
    };

    // Load Intercom script
    (() => {
      const w = window as CustomWindow;
      const ic: (...args: any[]) => void = w.Intercom;
      if (typeof ic === 'function') {
        ic('reattach_activator');
        ic('update', w.intercomSettings);
      } else {
        const d = document;
        const i: { (...args: any[]): void; q: any[] } = function (
          ...args: any[]
        ) {
          i.q.push(args);
        };
        i.q = [];
        (i as any).push = function (args: any) {
          i.q.push(args);
        };
        w.Intercom = i;
        const l = () => {
          const s = d.createElement('script');
          s.type = 'text/javascript';
          s.async = true;
          s.src = 'https://widget.intercom.io/widget/xz3rcybk';
          const x = d.getElementsByTagName('script')[0];
          if (x && x.parentNode) {
            x.parentNode.insertBefore(s, x);
          }
        };
        if (document.readyState === 'complete') {
          l();
        } else if (w.addEventListener) {
          w.addEventListener('load', l, false);
        } else if (w.attachEvent) {
          w.attachEvent('onload', l);
        }
      }
    })();
  }, []);

  let normalSideNavVisible = true;
  let superChatSideNavVisible = false;

  function toggleMenu() {
    normalSideNavVisible = !normalSideNavVisible;
    superChatSideNavVisible = !superChatSideNavVisible;

    // Force reflow to trigger CSS transitions
    document.body.offsetHeight;

    // Update UI
    renderMenus();
  }

  function renderMenus() {
    // No need to update classes based on visibility in JavaScript since the classes are directly applied in JSX
  }

  useEffect(() => {
    setShowSidebarInMobile(false);
    if (router) {
      console.log(router.route);
      if (router.query.formType) {
        setSelectedSidebarOption(router.query.formType as string | undefined);
        setHideTitle(false);
      } else if (router.route.includes('content')) {
        setSelectedSidebarOption(FormType.SOCIAL);
        setNormalSideNav(true);
        setSuperChatSideNav(false);
        setHideTitle(false);
      } else if (router.route.includes('brainstorm')) {
        setSelectedSidebarOption(FormType.MARKETINGIDEAS);
        setNormalSideNav(true);
        setSuperChatSideNav(false);
        setHideTitle(false);
      } else if (router.route.includes('campaigns')) {
        // if (router.route.includes('edit')) {
        //   setSelectedSidebarOption('Campaign Editor');
        // } else {
        //   setSelectedSidebarOption('Campaigns');
        // }
        setSelectedSidebarOption('');
        setNormalSideNav(true);
        setSuperChatSideNav(false);
        setHideTitle(true);
      } else if (router.route.includes('dashboard')) {
        setSelectedSidebarOption('Dashboard');
        setNormalSideNav(true);
        setSuperChatSideNav(false);
        setHideTitle(true);
      } else if (router.route.includes('settings')) {
        setSelectedSidebarOption('Settings');
        setNormalSideNav(true);
        setSuperChatSideNav(false);
        setHideTitle(false);
      } else if (router.route.includes('personaAutomation')) {
        setSelectedSidebarOption('Audience Finder');
        setNormalSideNav(true);
        setSuperChatSideNav(false);
        setHideTitle(false);
      } else if (router.route.includes('brandAutomation')) {
        setSelectedSidebarOption('Brand Finder');
        setNormalSideNav(true);
        setSuperChatSideNav(false);
        setHideTitle(false);
      } else if (router.route.includes('personas')) {
        setSelectedSidebarOption('Personas');
        setNormalSideNav(true);
        setSuperChatSideNav(false);
        setHideTitle(true);
      } else if (router.route.includes('tweet')) {
        setSelectedSidebarOption('tweet');
        setNormalSideNav(true);
        setSuperChatSideNav(false);
        setHideTitle(false);
      } else if (router.route.includes('instagram')) {
        setSelectedSidebarOption('Instagram Caption');
        setNormalSideNav(true);
        setSuperChatSideNav(false);
        setHideTitle(false);
      } else if (router.route.includes('facebook')) {
        setSelectedSidebarOption('Facebook Post');
        setNormalSideNav(true);
        setSuperChatSideNav(false);
        setHideTitle(false);
      } else if (router.route.includes('linkedin')) {
        setSelectedSidebarOption('LinkedIn Post');
        setNormalSideNav(true);
        setSuperChatSideNav(false);
        setHideTitle(false);
      } else if (router.route.includes('conciser')) {
        setSelectedSidebarOption('Simplify Copy');
        setNormalSideNav(true);
        setSuperChatSideNav(false);
        setHideTitle(false);
      } else if (router.route.includes('expander')) {
        setSelectedSidebarOption('Expand Copy');
        setNormalSideNav(true);
        setSuperChatSideNav(false);
        setHideTitle(false);
      } else if (router.route.includes('engager')) {
        setSelectedSidebarOption('Improve Copy');
        setNormalSideNav(true);
        setSuperChatSideNav(false);
        setHideTitle(false);
      } else if (router.route.includes('superChat')) {
        setSelectedSidebarOption('SuperChat');
        toggleMenu();
        setNormalSideNav(false);
        setSuperChatSideNav(true);
        setHideTitle(true);
      } else if (router.route.includes('/editor/content')) {
        setNormalSideNav(true);
        setSuperChatSideNav(false);
        setHideTitle(false);
      } else if (router.route.includes('saved')) {
        setSelectedSidebarOption('Content');
        setNormalSideNav(true);
        setSuperChatSideNav(false);
        setHideTitle(true);
      } else if (router.route.includes('templates')) {
        setSelectedSidebarOption('Templates');
        setNormalSideNav(true);
        setSuperChatSideNav(false);
        setHideTitle(true);
      }
    }
  }, [router]);

  const monitorWindowWidth = () => {
    if (window.innerWidth > 1000) {
      setIsInDesktopMode(true);
      setShowSidebarInMobile(false);
    } else {
      setIsInDesktopMode(false);
      setNormalSideNav(true);
      setSuperChatSideNav(false);
    }
  };

  useEffect(() => {
    if (typeof window !== 'undefined') {
      monitorWindowWidth();
      window.addEventListener('resize', monitorWindowWidth);
      return () => window.removeEventListener('resize', monitorWindowWidth);
    }
  }, []);

  useEffect(() => {
    if (showSidebarInMobile) {
      window.scrollTo({
        top: 0,
      });
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'auto';
    }
  }, [showSidebarInMobile]);

  return (
    <div className={styles['container']}>
      {normalSideNav && userData && !userData.featureLimitAccess && (
        <nav
          className={styles['navbar-container']}
          style={{
            display: showSidebarInMobile || isInDesktopMode ? '' : 'none',
          }}
        >
          <Link href="/dashboard">
            <h2 className={styles['name-container']}>
              <Image
                src="/icons/New_SC_LOGO.png"
                alt="Close"
                layout="fixed"
                objectFit="contain"
                height={40}
                width={170}
              />
            </h2>
          </Link>
          {/* <div className={styles['divider']}></div> */}
          <div className={styles['section']}>
            <SidebarOptionHeader
              isSelected={router.route.includes('dashboard')}
              text="Dashboard"
              href={'/dashboard'}
              DynamicIcon={
                <HomeMinimalSvg
                  stroke={
                    router.route.includes('dashboard')
                      ? 'var(--ea-black)'
                      : 'var(--ea-black)'
                  }
                />
              }
            />
          </div>

          <div className={styles['section']}>
            <SidebarOptionHeader
              isSelected={router.route.includes('campaign')}
              text="Campaigns"
              href={'/campaigns'}
              DynamicIcon={<CampaignsSVG />}
              simpleBadge={<SimpleBadge text="Beta" />}
            />
          </div>

          <div className={styles['section']}>
            <SidebarOptionHeader
              isSelected={router.route.includes('personas')}
              text="Personas"
              href={'/personas'}
              DynamicIcon={<PersonasSVG />}
              // simpleBadge={
              //   <SimpleBadge text='Beta' />
              // }
            />
          </div>

          {userData?.position !== 'Can View' && (
            <div className={styles['section']}>
              <SidebarOptionHeader
                isSelected={router.route.includes('superChat')}
                text="SuperChat"
                href={'/superChat'}
                DynamicIcon={<SuperChatSVG />}
              />
            </div>
          )}

          {userData?.position !== 'Can View' && (
            <div className={styles['section']}>
              <SidebarOptionHeader
                isSelected={router.route.includes('templates')}
                text="Templates"
                href={'/templates'}
                DynamicIcon={<TemplatesSVG />}
              />
            </div>
          )}

          <div className={styles['section4']}>
            <SidebarOptionHeader
              isSelected={router.route.includes('saved')}
              text="Content"
              href={'/saved'}
              DynamicIcon={<ContentSVG />}
            />
          </div>

          <div className={styles['divider']}></div>

          {userData?.position !== 'Can View' && (
            <div className={styles['scroll-area2']}>
              <div className={styles['section7']}>
                <NavSearch />
              </div>

              <div className={styles['scroll-area2']}>
                <div className={styles['section']}>
                  <h2 className={styles['gray']}>Templates</h2>
                </div>

                <div className={styles['section']}>
                  <SidebarOptionHeader
                    isSelected={
                      selectedSidebarOption === FormType.SOCIAL ||
                      selectedSidebarOption === FormType.PERSONA_TWITTER
                    }
                    text="Tweet"
                    href={'/editor/content?formType=tweet'}
                    DynamicIcon={
                      <TwitterSvg
                        stroke={
                          selectedSidebarOption === FormType.SOCIAL ||
                          selectedSidebarOption === FormType.PERSONA_TWITTER
                            ? 'var(--ea-black)'
                            : 'var(--ea-black)'
                        }
                      />
                    }
                  />
                </div>

                <div className={styles['section']}>
                  <SidebarOptionHeader
                    isSelected={
                      selectedSidebarOption === FormType.SOCIAL ||
                      selectedSidebarOption === FormType.PERSONA_INSTAGRAM
                    }
                    text="Instagram Caption"
                    href={'/editor/content?formType=instagram%20caption'}
                    DynamicIcon={
                      <InstagramSvg
                        stroke={
                          selectedSidebarOption === FormType.SOCIAL ||
                          selectedSidebarOption === FormType.PERSONA_INSTAGRAM
                            ? 'var(--ea-black)'
                            : 'var(--ea-black)'
                        }
                      />
                    }
                  />
                </div>

                <div className={styles['section']}>
                  <SidebarOptionHeader
                    isSelected={
                      selectedSidebarOption === FormType.SOCIAL ||
                      selectedSidebarOption === FormType.PERSONA_FACEBOOK
                    }
                    text="Facebook Post"
                    href={'/editor/content?formType=facebook%20post'}
                    DynamicIcon={
                      <FacebookSvg
                        stroke={
                          selectedSidebarOption === FormType.SOCIAL ||
                          selectedSidebarOption === FormType.PERSONA_FACEBOOK
                            ? 'var(--ea-black)'
                            : 'var(--ea-black)'
                        }
                      />
                    }
                  />
                </div>

                <div className={styles['section']}>
                  <SidebarOptionHeader
                    isSelected={
                      selectedSidebarOption === FormType.SOCIAL ||
                      selectedSidebarOption === FormType.PERSONA_LINKEDIN
                    }
                    text="LinkedIn Post"
                    href={'/editor/content?formType=linkedin%20post'}
                    DynamicIcon={
                      <LinkedInSvg
                        stroke={
                          selectedSidebarOption === FormType.SOCIAL ||
                          selectedSidebarOption === FormType.PERSONA_LINKEDIN
                            ? 'var(--ea-black)'
                            : 'var(--ea-black)'
                        }
                      />
                    }
                  />
                </div>

                <div className={styles['section']}>
                  <SidebarOptionHeader
                    isSelected={
                      selectedSidebarOption === FormType.EMAIL ||
                      selectedSidebarOption === FormType.PERSONAEMAIL
                    }
                    text="Email"
                    href={'/editor/content?formType=persona%20email'}
                    DynamicIcon={
                      <EmailSvg
                        stroke={
                          selectedSidebarOption === FormType.EMAIL ||
                          selectedSidebarOption === FormType.PERSONAEMAIL
                            ? 'var(--ea-black)'
                            : 'var(--ea-black)'
                        }
                      />
                    }
                  />
                </div>

                <div className={styles['section']}>
                  <SidebarOptionHeader
                    isSelected={
                      selectedSidebarOption === FormType.BLOG ||
                      selectedSidebarOption === FormType.PERSONABLOG
                    }
                    text="Blog Post"
                    href={'/editor/content?formType=persona%20blog%20post'}
                    DynamicIcon={
                      <NewBlogSvg
                        stroke={
                          selectedSidebarOption === FormType.BLOG ||
                          selectedSidebarOption === FormType.PERSONABLOG
                            ? 'var(--ea-black)'
                            : 'var(--ea-black)'
                        }
                      />
                    }
                  />
                </div>

                <div className={styles['section']}>
                  <SidebarOptionHeader
                    isSelected={
                      selectedSidebarOption === FormType.PERSONA_NEWSLETTER
                    }
                    text="Newsletter"
                    href={'/editor/content?formType=persona%20newsletter'}
                    DynamicIcon={
                      <NewsletterSvg
                        stroke={
                          selectedSidebarOption === FormType.PERSONA_NEWSLETTER
                            ? 'var(--ea-black)'
                            : 'var(--ea-black)'
                        }
                      />
                    }
                    //   simpleBadge={
                    //   <SimpleBadge text='New!' />
                    // }
                  />
                </div>

                <div className={styles['section5']}>
                  <SidebarOptionHeader
                    isSelected={false}
                    text="Show All"
                    href={'/templates'}
                    DynamicIcon={<ShowMoreSvg stroke={'var(--ea-black)'} />}
                  />
                </div>

                {/* <div className={styles['section']}>
        <SidebarOptionHeader
            isSelected={selectedSidebarOption === FormType.HERO_HEADER ||  selectedSidebarOption === FormType.PERSONA_HERO_HEADER}
            text="Website Header"
            href={`/editor/website?formType=${FormType.PERSONA_HERO_HEADER}`}
            DynamicIcon={
              <WebsiteHeaderSvg
                stroke={
                  selectedSidebarOption === FormType.HERO_HEADER ||  selectedSidebarOption === FormType.PERSONA_HERO_HEADER
                    ? 'var(--ea-black)'
                    : 'var(--ea-white)'
                }
              />
            }
          />
          </div>

          <div className={styles['section']}>
        <SidebarOptionHeader
            isSelected={selectedSidebarOption === FormType.SUBHEADER || selectedSidebarOption === FormType.PERSONA_SUBHEADER}
            text="Website Subheader"
            href={`/editor/website?formType=${FormType.PERSONA_SUBHEADER}`}
            DynamicIcon={
              <WebsiteSubheaderSvg
                stroke={
                  selectedSidebarOption === FormType.SUBHEADER || selectedSidebarOption === FormType.PERSONA_SUBHEADER
                    ? 'var(--ea-black)'
                    : 'var(--ea-white)'
                }
              />
            }
          />
          </div>

          <div className={styles['section']}>
        <SidebarOptionHeader
            isSelected={selectedSidebarOption === FormType.FEATURE_DESCRIPTION ||  selectedSidebarOption === FormType.PERSONA_FEATURE_DESCRIPTION}
            text="Feature Description"
            href={`/editor/website?formType=${FormType.PERSONA_FEATURE_DESCRIPTION}`}
            DynamicIcon={
              <FeatureDescriptionSvg
                stroke={
                  selectedSidebarOption === FormType.FEATURE_DESCRIPTION ||  selectedSidebarOption === FormType.PERSONA_FEATURE_DESCRIPTION
                    ? 'var(--ea-black)'
                    : 'var(--ea-white)'
                }
              />
            }
          />
          </div>

          <div className={styles['section']}>
        <SidebarOptionHeader
            isSelected={selectedSidebarOption === FormType.CALL_TO_ACTION ||  selectedSidebarOption === FormType.PERSONA_CALL_TO_ACTION}
            text="Call To Action"
            href={`/editor/website?formType=${FormType.PERSONA_CALL_TO_ACTION}`}
            DynamicIcon={
              <CallToActionSvg
                stroke={
                  selectedSidebarOption === FormType.CALL_TO_ACTION ||  selectedSidebarOption === FormType.PERSONA_CALL_TO_ACTION
                    ? 'var(--ea-black)'
                    : 'var(--ea-white)'
                }
              />
            }
          />
          </div> */}

                <div className={styles['space']}></div>
                <div className={styles['section']}>
                  <h2 className={styles['gray']}>Toolkit</h2>
                </div>

                {userData?.position !== 'Content Creator' && (
                  <div className={styles['section']}>
                    <SidebarOptionHeader
                      isSelected={router.route.includes('brandAutomation')}
                      text="Brand Finder"
                      href={'/brandAutomation'}
                      DynamicIcon={
                        <CompassSvg
                          stroke={
                            router.route.includes('brandAutomation')
                              ? 'var(--ea-black)'
                              : 'var(--ea-black)'
                          }
                        />
                      }
                    />
                  </div>
                )}
                {userData?.position !== 'Content Creator' && (
                  <div className={styles['section']}>
                    <SidebarOptionHeader
                      isSelected={router.route.includes('personaAutomation')}
                      text="Audience Finder"
                      href={'/personaAutomation'}
                      DynamicIcon={
                        <SearchSvg
                          stroke={
                            router.route.includes('personaAutomation')
                              ? 'var(--ea-black)'
                              : 'var(--ea-black)'
                          }
                        />
                      }
                    />
                  </div>
                )}

                <div className={styles['section']}>
                  <SidebarOptionHeader
                    isSelected={
                      selectedSidebarOption === FormType.MARKETINGIDEAS ||
                      selectedSidebarOption === FormType.PERSONAMARKETINGIDEAS
                    }
                    text="Campaign Brainstorm"
                    href={`/editor/brainstorm?formType=${FormType.PERSONAMARKETINGIDEAS}`}
                    DynamicIcon={
                      <CampaignBrainstormSvg
                        stroke={
                          selectedSidebarOption === FormType.MARKETINGIDEAS ||
                          selectedSidebarOption ===
                            FormType.PERSONAMARKETINGIDEAS
                            ? 'var(--ea-black)'
                            : 'var(--ea-black)'
                        }
                      />
                    }
                  />
                </div>

                <div className={styles['section']}>
                  <SidebarOptionHeader
                    isSelected={
                      selectedSidebarOption === FormType.CONTENT_IMPROVER ||
                      selectedSidebarOption === FormType.CONTENT_ENGAGER
                    }
                    text="Improve Copy"
                    href={'/editor/toolkit?formType=improve%20copy'}
                    DynamicIcon={
                      <ContentEngagerSvg
                        stroke={
                          selectedSidebarOption === FormType.CONTENT_IMPROVER ||
                          selectedSidebarOption === FormType.CONTENT_ENGAGER
                            ? 'var(--ea-black)'
                            : 'var(--ea-black)'
                        }
                      />
                    }
                  />
                </div>

                <div className={styles['section']}>
                  <SidebarOptionHeader
                    isSelected={
                      selectedSidebarOption === FormType.CONTENT_IMPROVER ||
                      selectedSidebarOption === FormType.CONTENT_CONCISER
                    }
                    text="Simplify Copy"
                    href={'/editor/toolkit?formType=simplify%20copy'}
                    DynamicIcon={
                      <ContentConciserSvg
                        stroke={
                          selectedSidebarOption === FormType.CONTENT_IMPROVER ||
                          selectedSidebarOption === FormType.CONTENT_CONCISER
                            ? 'var(--ea-black)'
                            : 'var(--ea-black)'
                        }
                      />
                    }
                  />
                </div>

                <div className={styles['section']}>
                  <SidebarOptionHeader
                    isSelected={
                      selectedSidebarOption === FormType.CONTENT_IMPROVER ||
                      selectedSidebarOption === FormType.CONTENT_EXPANDER
                    }
                    text="Expand Copy"
                    href={'/editor/toolkit?formType=expand%20copy'}
                    DynamicIcon={
                      <ContentExpanderSvg
                        stroke={
                          selectedSidebarOption === FormType.CONTENT_IMPROVER ||
                          selectedSidebarOption === FormType.CONTENT_EXPANDER
                            ? 'var(--ea-black)'
                            : 'var(--ea-black)'
                        }
                      />
                    }
                  />
                </div>

                <div className={styles['section']}>
                  <SidebarOptionHeader
                    isSelected={
                      selectedSidebarOption === FormType.AUDIENCE_CHANGER
                    }
                    text="Audience Changer"
                    href={'/editor/toolkit?formType=audience%20changer'}
                    DynamicIcon={
                      <AudienceChangerSvg
                        stroke={
                          selectedSidebarOption === FormType.AUDIENCE_CHANGER
                            ? 'var(--ea-black)'
                            : 'var(--ea-black)'
                        }
                      />
                    }
                  />
                </div>

                <div className={styles['section']}>
                  <SidebarOptionHeader
                    isSelected={
                      selectedSidebarOption === FormType.FORMAT_CHANGER
                    }
                    text="Format Changer"
                    href={'/editor/toolkit?formType=format%20changer'}
                    DynamicIcon={
                      <FormatChangerSvg
                        stroke={
                          selectedSidebarOption === FormType.FORMAT_CHANGER
                            ? 'var(--ea-black)'
                            : 'var(--ea-black)'
                        }
                      />
                    }
                  />
                </div>

                <div className={styles['section']}>
                  <SidebarOptionHeader
                    isSelected={selectedSidebarOption === FormType.TONE_CHANGER}
                    text="Tone Changer"
                    href={'/editor/toolkit?formType=tone%20changer'}
                    DynamicIcon={
                      <ToneChangerSvg
                        stroke={
                          selectedSidebarOption === FormType.TONE_CHANGER
                            ? 'var(--ea-black)'
                            : 'var(--ea-black)'
                        }
                      />
                    }
                  />
                </div>

                {/* <div className={styles['section']}>
          <h2 className={styles['gray']}>AI Toolkit</h2>
            </div> */}
                {/* <Link href="/chat">
          <a className={styles['section']}>
          <h2>
          <Image
                  src="/icons/Settings_White.svg"
                  alt="Close"
                  layout="fixed"
                  objectFit="contain"
                  height={12}
                  width={12}
                />
            SuperChat</h2>
            {numInvitesRemaining > 0 && (
              <SimpleTag
                text={`${numInvitesRemaining}`}
                backgroundColor="var(--ea-magenta)"
                border="none"
                color="var(--ea-white)"
              />
            )}
          </a>
        </Link> */}

                {/* <Link href="/personaAutomation">
          <a className={styles['section']}>
          <h2>
          <Image
                  src="/icons/Settings_White.svg"
                  alt="Close"
                  layout="fixed"
                  objectFit="contain"
                  height={12}
                  width={12}
                />
            Persona Finder</h2>
           
          </a>
        </Link> */}

                {/* {showWebsiteOptions && (
          <div className={styles['side-options']}>
          <SidebarOption
            isSelected={selectedSidebarOption === FormType.HERO_HEADER ||  selectedSidebarOption === FormType.PERSONA_HERO_HEADER}
            text="Hero Header"
            href={`/editor/website?formType=${FormType.PERSONA_HERO_HEADER}`}
          />
          <SidebarOption
            isSelected={selectedSidebarOption === FormType.SUBHEADER || selectedSidebarOption === FormType.PERSONA_SUBHEADER}
            text="Sub Header"
            href={`/editor/website?formType=${FormType.PERSONA_SUBHEADER}`}
          />
          <SidebarOption
            isSelected={selectedSidebarOption === FormType.FEATURE_DESCRIPTION ||  selectedSidebarOption === FormType.PERSONA_FEATURE_DESCRIPTION}
            text="Feature Description"
            href={`/editor/website?formType=${FormType.PERSONA_FEATURE_DESCRIPTION}`}
          />
          <SidebarOption
            isSelected={selectedSidebarOption === FormType.CALL_TO_ACTION ||  selectedSidebarOption === FormType.PERSONA_CALL_TO_ACTION}
            text="Call To Action"
            href={`/editor/website?formType=${FormType.PERSONA_CALL_TO_ACTION}`}
          />
          </div>
          )} */}
              </div>
            </div>
          )}

          <div className={styles['section-end']}>
            {/* <div className={styles['ending-divider']}></div> */}

            <Link href="/settings">
              <a className={styles['section']}>
                <h2>
                  <Image
                    src="/icons/Settings.svg"
                    alt="Close"
                    layout="fixed"
                    objectFit="contain"
                    height={12}
                    width={12}
                  />
                  Settings
                </h2>
                {numInvitesRemaining > 0 && (
                  <SimpleTag
                    text={`${numInvitesRemaining}`}
                    backgroundColor="var(--ea-magenta)"
                    border="none"
                    color="var(--ea-white)"
                  />
                )}
              </a>
            </Link>
          </div>
        </nav>
      )}
      {normalSideNav &&
        userData?.featureLimitAccess === true &&
        (!userData.limitFeatureName ||
          userData.limitFeatureName === 'All Access') && (
          //they have feature limit access but are currently full access
          <nav
            className={styles['navbar-container']}
            style={{
              display: showSidebarInMobile || isInDesktopMode ? '' : 'none',
            }}
          >
            <Link href="/dashboard">
              <h2 className={styles['name-container']}>
                <Image
                  src="/icons/New_SC_LOGO.png"
                  alt="Close"
                  layout="fixed"
                  objectFit="contain"
                  height={40}
                  width={170}
                />
              </h2>
            </Link>
            {/* <div className={styles['divider']}></div> */}
            <div className={styles['section']}>
              <SidebarOptionHeader
                isSelected={router.route.includes('dashboard')}
                text="Dashboard"
                href={'/dashboard'}
                DynamicIcon={
                  <HomeMinimalSvg
                    stroke={
                      router.route.includes('dashboard')
                        ? 'var(--ea-black)'
                        : 'var(--ea-black)'
                    }
                  />
                }
              />
            </div>

            <div className={styles['section']}>
              <SidebarOptionHeader
                isSelected={router.route.includes('campaign')}
                text="Campaigns"
                href={'/campaigns'}
                DynamicIcon={<CampaignsSVG />}
                simpleBadge={<SimpleBadge text="Beta" />}
              />
            </div>

            <div className={styles['section']}>
              <SidebarOptionHeader
                isSelected={router.route.includes('personas')}
                text="Personas"
                href={'/personas'}
                DynamicIcon={<PersonasSVG />}
                // simpleBadge={
                //   <SimpleBadge text='Beta' />
                // }
              />
            </div>

            {userData?.position !== 'Can View' && (
              <div className={styles['section']}>
                <SidebarOptionHeader
                  isSelected={router.route.includes('superChat')}
                  text="SuperChat"
                  href={'/superChat'}
                  DynamicIcon={<SuperChatSVG />}
                />
              </div>
            )}

            {userData?.position !== 'Can View' && (
              <div className={styles['section']}>
                <SidebarOptionHeader
                  isSelected={router.route.includes('templates')}
                  text="Templates"
                  href={'/templates'}
                  DynamicIcon={<TemplatesSVG />}
                />
              </div>
            )}

            <div className={styles['section4']}>
              <SidebarOptionHeader
                isSelected={router.route.includes('saved')}
                text="Content"
                href={'/saved'}
                DynamicIcon={<ContentSVG />}
              />
            </div>

            <div className={styles['divider']}></div>

            {userData?.position !== 'Can View' && (
              <div className={styles['scroll-area2']}>
                <div className={styles['section7']}>
                  <NavSearch />
                </div>

                <div className={styles['scroll-area2']}>
                  <div className={styles['section']}>
                    <h2 className={styles['gray']}>Templates</h2>
                  </div>

                  <div className={styles['section']}>
                    <SidebarOptionHeader
                      isSelected={
                        selectedSidebarOption === FormType.SOCIAL ||
                        selectedSidebarOption === FormType.PERSONA_TWITTER
                      }
                      text="Tweet"
                      href={'/editor/content?formType=tweet'}
                      DynamicIcon={
                        <TwitterSvg
                          stroke={
                            selectedSidebarOption === FormType.SOCIAL ||
                            selectedSidebarOption === FormType.PERSONA_TWITTER
                              ? 'var(--ea-black)'
                              : 'var(--ea-black)'
                          }
                        />
                      }
                    />
                  </div>

                  <div className={styles['section']}>
                    <SidebarOptionHeader
                      isSelected={
                        selectedSidebarOption === FormType.SOCIAL ||
                        selectedSidebarOption === FormType.PERSONA_INSTAGRAM
                      }
                      text="Instagram Caption"
                      href={'/editor/content?formType=instagram%20caption'}
                      DynamicIcon={
                        <InstagramSvg
                          stroke={
                            selectedSidebarOption === FormType.SOCIAL ||
                            selectedSidebarOption === FormType.PERSONA_INSTAGRAM
                              ? 'var(--ea-black)'
                              : 'var(--ea-black)'
                          }
                        />
                      }
                    />
                  </div>

                  <div className={styles['section']}>
                    <SidebarOptionHeader
                      isSelected={
                        selectedSidebarOption === FormType.SOCIAL ||
                        selectedSidebarOption === FormType.PERSONA_FACEBOOK
                      }
                      text="Facebook Post"
                      href={'/editor/content?formType=facebook%20post'}
                      DynamicIcon={
                        <FacebookSvg
                          stroke={
                            selectedSidebarOption === FormType.SOCIAL ||
                            selectedSidebarOption === FormType.PERSONA_FACEBOOK
                              ? 'var(--ea-black)'
                              : 'var(--ea-black)'
                          }
                        />
                      }
                    />
                  </div>

                  <div className={styles['section']}>
                    <SidebarOptionHeader
                      isSelected={
                        selectedSidebarOption === FormType.SOCIAL ||
                        selectedSidebarOption === FormType.PERSONA_LINKEDIN
                      }
                      text="LinkedIn Post"
                      href={'/editor/content?formType=linkedin%20post'}
                      DynamicIcon={
                        <LinkedInSvg
                          stroke={
                            selectedSidebarOption === FormType.SOCIAL ||
                            selectedSidebarOption === FormType.PERSONA_LINKEDIN
                              ? 'var(--ea-black)'
                              : 'var(--ea-black)'
                          }
                        />
                      }
                    />
                  </div>

                  <div className={styles['section']}>
                    <SidebarOptionHeader
                      isSelected={
                        selectedSidebarOption === FormType.EMAIL ||
                        selectedSidebarOption === FormType.PERSONAEMAIL
                      }
                      text="Email"
                      href={'/editor/content?formType=persona%20email'}
                      DynamicIcon={
                        <EmailSvg
                          stroke={
                            selectedSidebarOption === FormType.EMAIL ||
                            selectedSidebarOption === FormType.PERSONAEMAIL
                              ? 'var(--ea-black)'
                              : 'var(--ea-black)'
                          }
                        />
                      }
                    />
                  </div>

                  <div className={styles['section']}>
                    <SidebarOptionHeader
                      isSelected={
                        selectedSidebarOption === FormType.BLOG ||
                        selectedSidebarOption === FormType.PERSONABLOG
                      }
                      text="Blog Post"
                      href={'/editor/content?formType=persona%20blog%20post'}
                      DynamicIcon={
                        <NewBlogSvg
                          stroke={
                            selectedSidebarOption === FormType.BLOG ||
                            selectedSidebarOption === FormType.PERSONABLOG
                              ? 'var(--ea-black)'
                              : 'var(--ea-black)'
                          }
                        />
                      }
                    />
                  </div>

                  <div className={styles['section']}>
                    <SidebarOptionHeader
                      isSelected={
                        selectedSidebarOption === FormType.PERSONA_NEWSLETTER
                      }
                      text="Newsletter"
                      href={'/editor/content?formType=persona%20newsletter'}
                      DynamicIcon={
                        <NewsletterSvg
                          stroke={
                            selectedSidebarOption ===
                            FormType.PERSONA_NEWSLETTER
                              ? 'var(--ea-black)'
                              : 'var(--ea-black)'
                          }
                        />
                      }
                      //   simpleBadge={
                      //   <SimpleBadge text='New!' />
                      // }
                    />
                  </div>

                  <div className={styles['section5']}>
                    <SidebarOptionHeader
                      isSelected={false}
                      text="Show All"
                      href={'/templates'}
                      DynamicIcon={<ShowMoreSvg stroke={'var(--ea-black)'} />}
                    />
                  </div>

                  {/* <div className={styles['section']}>
      <SidebarOptionHeader
          isSelected={selectedSidebarOption === FormType.HERO_HEADER ||  selectedSidebarOption === FormType.PERSONA_HERO_HEADER}
          text="Website Header"
          href={`/editor/website?formType=${FormType.PERSONA_HERO_HEADER}`}
          DynamicIcon={
            <WebsiteHeaderSvg
              stroke={
                selectedSidebarOption === FormType.HERO_HEADER ||  selectedSidebarOption === FormType.PERSONA_HERO_HEADER
                  ? 'var(--ea-black)'
                  : 'var(--ea-white)'
              }
            />
          }
        />
        </div>

        <div className={styles['section']}>
      <SidebarOptionHeader
          isSelected={selectedSidebarOption === FormType.SUBHEADER || selectedSidebarOption === FormType.PERSONA_SUBHEADER}
          text="Website Subheader"
          href={`/editor/website?formType=${FormType.PERSONA_SUBHEADER}`}
          DynamicIcon={
            <WebsiteSubheaderSvg
              stroke={
                selectedSidebarOption === FormType.SUBHEADER || selectedSidebarOption === FormType.PERSONA_SUBHEADER
                  ? 'var(--ea-black)'
                  : 'var(--ea-white)'
              }
            />
          }
        />
        </div>

        <div className={styles['section']}>
      <SidebarOptionHeader
          isSelected={selectedSidebarOption === FormType.FEATURE_DESCRIPTION ||  selectedSidebarOption === FormType.PERSONA_FEATURE_DESCRIPTION}
          text="Feature Description"
          href={`/editor/website?formType=${FormType.PERSONA_FEATURE_DESCRIPTION}`}
          DynamicIcon={
            <FeatureDescriptionSvg
              stroke={
                selectedSidebarOption === FormType.FEATURE_DESCRIPTION ||  selectedSidebarOption === FormType.PERSONA_FEATURE_DESCRIPTION
                  ? 'var(--ea-black)'
                  : 'var(--ea-white)'
              }
            />
          }
        />
        </div>

        <div className={styles['section']}>
      <SidebarOptionHeader
          isSelected={selectedSidebarOption === FormType.CALL_TO_ACTION ||  selectedSidebarOption === FormType.PERSONA_CALL_TO_ACTION}
          text="Call To Action"
          href={`/editor/website?formType=${FormType.PERSONA_CALL_TO_ACTION}`}
          DynamicIcon={
            <CallToActionSvg
              stroke={
                selectedSidebarOption === FormType.CALL_TO_ACTION ||  selectedSidebarOption === FormType.PERSONA_CALL_TO_ACTION
                  ? 'var(--ea-black)'
                  : 'var(--ea-white)'
              }
            />
          }
        />
        </div> */}

                  <div className={styles['space']}></div>
                  <div className={styles['section']}>
                    <h2 className={styles['gray']}>Toolkit</h2>
                  </div>
                  {userData?.position !== 'Content Creator' && (
                    <div className={styles['section']}>
                      <SidebarOptionHeader
                        isSelected={router.route.includes('brandAutomation')}
                        text="Brand Finder"
                        href={'/brandAutomation'}
                        DynamicIcon={
                          <CompassSvg
                            stroke={
                              router.route.includes('brandAutomation')
                                ? 'var(--ea-black)'
                                : 'var(--ea-black)'
                            }
                          />
                        }
                      />
                    </div>
                  )}
                  {userData?.position !== 'Content Creator' && (
                    <div className={styles['section']}>
                      <SidebarOptionHeader
                        isSelected={router.route.includes('personaAutomation')}
                        text="Audience Finder"
                        href={'/personaAutomation'}
                        DynamicIcon={
                          <SearchSvg
                            stroke={
                              router.route.includes('personaAutomation')
                                ? 'var(--ea-black)'
                                : 'var(--ea-black)'
                            }
                          />
                        }
                      />
                    </div>
                  )}

                  <div className={styles['section']}>
                    <SidebarOptionHeader
                      isSelected={
                        selectedSidebarOption === FormType.MARKETINGIDEAS ||
                        selectedSidebarOption === FormType.PERSONAMARKETINGIDEAS
                      }
                      text="Campaign Brainstorm"
                      href={`/editor/brainstorm?formType=${FormType.PERSONAMARKETINGIDEAS}`}
                      DynamicIcon={
                        <CampaignBrainstormSvg
                          stroke={
                            selectedSidebarOption === FormType.MARKETINGIDEAS ||
                            selectedSidebarOption ===
                              FormType.PERSONAMARKETINGIDEAS
                              ? 'var(--ea-black)'
                              : 'var(--ea-black)'
                          }
                        />
                      }
                    />
                  </div>

                  <div className={styles['section']}>
                    <SidebarOptionHeader
                      isSelected={
                        selectedSidebarOption === FormType.CONTENT_IMPROVER ||
                        selectedSidebarOption === FormType.CONTENT_ENGAGER
                      }
                      text="Improve Copy"
                      href={'/editor/toolkit?formType=improve%20copy'}
                      DynamicIcon={
                        <ContentEngagerSvg
                          stroke={
                            selectedSidebarOption ===
                              FormType.CONTENT_IMPROVER ||
                            selectedSidebarOption === FormType.CONTENT_ENGAGER
                              ? 'var(--ea-black)'
                              : 'var(--ea-black)'
                          }
                        />
                      }
                    />
                  </div>

                  <div className={styles['section']}>
                    <SidebarOptionHeader
                      isSelected={
                        selectedSidebarOption === FormType.CONTENT_IMPROVER ||
                        selectedSidebarOption === FormType.CONTENT_CONCISER
                      }
                      text="Simplify Copy"
                      href={'/editor/toolkit?formType=simplify%20copy'}
                      DynamicIcon={
                        <ContentConciserSvg
                          stroke={
                            selectedSidebarOption ===
                              FormType.CONTENT_IMPROVER ||
                            selectedSidebarOption === FormType.CONTENT_CONCISER
                              ? 'var(--ea-black)'
                              : 'var(--ea-black)'
                          }
                        />
                      }
                    />
                  </div>

                  <div className={styles['section']}>
                    <SidebarOptionHeader
                      isSelected={
                        selectedSidebarOption === FormType.CONTENT_IMPROVER ||
                        selectedSidebarOption === FormType.CONTENT_EXPANDER
                      }
                      text="Expand Copy"
                      href={'/editor/toolkit?formType=expand%20copy'}
                      DynamicIcon={
                        <ContentExpanderSvg
                          stroke={
                            selectedSidebarOption ===
                              FormType.CONTENT_IMPROVER ||
                            selectedSidebarOption === FormType.CONTENT_EXPANDER
                              ? 'var(--ea-black)'
                              : 'var(--ea-black)'
                          }
                        />
                      }
                    />
                  </div>

                  <div className={styles['section']}>
                    <SidebarOptionHeader
                      isSelected={
                        selectedSidebarOption === FormType.AUDIENCE_CHANGER
                      }
                      text="Audience Changer"
                      href={'/editor/toolkit?formType=audience%20changer'}
                      DynamicIcon={
                        <AudienceChangerSvg
                          stroke={
                            selectedSidebarOption === FormType.AUDIENCE_CHANGER
                              ? 'var(--ea-black)'
                              : 'var(--ea-black)'
                          }
                        />
                      }
                    />
                  </div>

                  <div className={styles['section']}>
                    <SidebarOptionHeader
                      isSelected={
                        selectedSidebarOption === FormType.FORMAT_CHANGER
                      }
                      text="Format Changer"
                      href={'/editor/toolkit?formType=format%20changer'}
                      DynamicIcon={
                        <FormatChangerSvg
                          stroke={
                            selectedSidebarOption === FormType.FORMAT_CHANGER
                              ? 'var(--ea-black)'
                              : 'var(--ea-black)'
                          }
                        />
                      }
                    />
                  </div>

                  <div className={styles['section']}>
                    <SidebarOptionHeader
                      isSelected={
                        selectedSidebarOption === FormType.TONE_CHANGER
                      }
                      text="Tone Changer"
                      href={'/editor/toolkit?formType=tone%20changer'}
                      DynamicIcon={
                        <ToneChangerSvg
                          stroke={
                            selectedSidebarOption === FormType.TONE_CHANGER
                              ? 'var(--ea-black)'
                              : 'var(--ea-black)'
                          }
                        />
                      }
                    />
                  </div>

                  {/* <div className={styles['section']}>
        <h2 className={styles['gray']}>AI Toolkit</h2>
          </div> */}
                  {/* <Link href="/chat">
        <a className={styles['section']}>
        <h2>
        <Image
                src="/icons/Settings_White.svg"
                alt="Close"
                layout="fixed"
                objectFit="contain"
                height={12}
                width={12}
              />
          SuperChat</h2>
          {numInvitesRemaining > 0 && (
            <SimpleTag
              text={`${numInvitesRemaining}`}
              backgroundColor="var(--ea-magenta)"
              border="none"
              color="var(--ea-white)"
            />
          )}
        </a>
      </Link> */}

                  {/* <Link href="/personaAutomation">
        <a className={styles['section']}>
        <h2>
        <Image
                src="/icons/Settings_White.svg"
                alt="Close"
                layout="fixed"
                objectFit="contain"
                height={12}
                width={12}
              />
          Persona Finder</h2>
         
        </a>
      </Link> */}

                  {/* {showWebsiteOptions && (
        <div className={styles['side-options']}>
        <SidebarOption
          isSelected={selectedSidebarOption === FormType.HERO_HEADER ||  selectedSidebarOption === FormType.PERSONA_HERO_HEADER}
          text="Hero Header"
          href={`/editor/website?formType=${FormType.PERSONA_HERO_HEADER}`}
        />
        <SidebarOption
          isSelected={selectedSidebarOption === FormType.SUBHEADER || selectedSidebarOption === FormType.PERSONA_SUBHEADER}
          text="Sub Header"
          href={`/editor/website?formType=${FormType.PERSONA_SUBHEADER}`}
        />
        <SidebarOption
          isSelected={selectedSidebarOption === FormType.FEATURE_DESCRIPTION ||  selectedSidebarOption === FormType.PERSONA_FEATURE_DESCRIPTION}
          text="Feature Description"
          href={`/editor/website?formType=${FormType.PERSONA_FEATURE_DESCRIPTION}`}
        />
        <SidebarOption
          isSelected={selectedSidebarOption === FormType.CALL_TO_ACTION ||  selectedSidebarOption === FormType.PERSONA_CALL_TO_ACTION}
          text="Call To Action"
          href={`/editor/website?formType=${FormType.PERSONA_CALL_TO_ACTION}`}
        />
        </div>
        )} */}
                </div>
              </div>
            )}

            <div className={styles['section-end']}>
              {/* <div className={styles['ending-divider']}></div> */}

              <Link href="/settings">
                <a className={styles['section']}>
                  <h2>
                    <Image
                      src="/icons/Settings.svg"
                      alt="Close"
                      layout="fixed"
                      objectFit="contain"
                      height={12}
                      width={12}
                    />
                    Settings
                  </h2>
                  {numInvitesRemaining > 0 && (
                    <SimpleTag
                      text={`${numInvitesRemaining}`}
                      backgroundColor="var(--ea-magenta)"
                      border="none"
                      color="var(--ea-white)"
                    />
                  )}
                </a>
              </Link>
            </div>
          </nav>
        )}
      {normalSideNav &&
        userData?.featureLimitAccess === true &&
        userData.limitFeatureName &&
        userData?.limitFeatureName.length > 0 && (
          <nav
            className={styles['navbar-container']}
            style={{
              display: showSidebarInMobile || isInDesktopMode ? '' : 'none',
            }}
          >
            <Link href="/dashboard">
              <h2 className={styles['name-container']}>
                <Image
                  src="/icons/New_SC_LOGO.png"
                  alt="Close"
                  layout="fixed"
                  objectFit="contain"
                  height={40}
                  width={170}
                />
              </h2>
            </Link>

            <div className={styles['section']}>
              <SidebarOptionHeader
                isSelected={router.route.includes('dashboard')}
                text="Dashboard"
                href={'/dashboard'}
                DynamicIcon={
                  <HomeMinimalSvg
                    stroke={
                      router.route.includes('dashboard')
                        ? 'var(--ea-black)'
                        : 'var(--ea-black)'
                    }
                  />
                }
              />
            </div>

            {userData.allowedFeatures?.includes('Campaigns') && (
              <div className={styles['section']}>
                <SidebarOptionHeader
                  isSelected={router.route.includes('campaign')}
                  text="Campaigns"
                  href={'/campaigns'}
                  DynamicIcon={<CampaignsSVG />}
                  simpleBadge={<SimpleBadge text="Beta" />}
                />
              </div>
            )}
            {userData.allowedFeatures?.includes('Persona Library') && (
              <div className={styles['section']}>
                <SidebarOptionHeader
                  isSelected={router.route.includes('personas')}
                  text="Personas"
                  href={'/personas'}
                  DynamicIcon={<PersonasSVG />}
                  // simpleBadge={
                  //   <SimpleBadge text='Beta' />
                  // }
                />
              </div>
            )}

            {userData.allowedFeatures?.includes('SuperChat') &&
              userData?.position !== 'Can View' && (
                <div className={styles['section']}>
                  <SidebarOptionHeader
                    isSelected={router.route.includes('superChat')}
                    text="SuperChat"
                    href={'/superChat'}
                    DynamicIcon={<SuperChatSVG />}
                  />
                </div>
              )}

            {userData.allowedFeatures?.includes('Templates') &&
              userData?.position !== 'Can View' && (
                <div className={styles['section']}>
                  <SidebarOptionHeader
                    isSelected={router.route.includes('templates')}
                    text="Templates"
                    href={'/templates'}
                    DynamicIcon={<TemplatesSVG />}
                  />
                </div>
              )}

            <div className={styles['section4']}>
              <SidebarOptionHeader
                isSelected={router.route.includes('saved')}
                text="Content"
                href={'/saved'}
                DynamicIcon={<ContentSVG />}
              />
            </div>

            <div className={styles['divider']}></div>

            {userData?.position !== 'Can View' && (
              <div className={styles['scroll-area2']}>
                <div className={styles['section7']}>
                  <NavSearch />
                </div>

                <div className={styles['scroll-area2']}>
                  <div className={styles['section']}>
                    <h2 className={styles['gray']}>Templates</h2>
                  </div>
                  {/*  */}

                  {userData.pinnedItems?.includes('Audience Finder') && (
                    <div className={styles['section']}>
                      <SidebarOptionHeader
                        isSelected={router.route.includes('personaAutomation')}
                        text="Audience Finder"
                        href={'/personaAutomation'}
                        DynamicIcon={
                          <Image
                            src="/icons/SearchBlack.svg"
                            alt="Audience Finder"
                            layout="fixed"
                            objectFit="contain"
                            height={14}
                            width={14}
                          />
                        }
                      />
                    </div>
                  )}

                  {userData.pinnedItems?.includes('Facebook Post') && (
                    <div className={styles['section']}>
                      <SidebarOptionHeader
                        isSelected={
                          selectedSidebarOption === FormType.PERSONA_FACEBOOK
                        }
                        text="Facebook Post"
                        href={'/editor/content?formType=facebook%20post'}
                        DynamicIcon={
                          <Image
                            src="/icons/Facebook.svg"
                            alt="Facebook Post"
                            layout="fixed"
                            objectFit="contain"
                            height={14}
                            width={14}
                          />
                        }
                      />
                    </div>
                  )}

                  {userData.pinnedItems?.includes('Instagram Caption') && (
                    <div className={styles['section']}>
                      <SidebarOptionHeader
                        isSelected={
                          selectedSidebarOption === FormType.PERSONA_INSTAGRAM
                        }
                        text="Instagram Caption"
                        href={'/editor/content?formType=instagram%20caption'}
                        DynamicIcon={
                          <Image
                            src="/icons/Instagram.svg"
                            alt="Instagram Caption"
                            layout="fixed"
                            objectFit="contain"
                            height={14}
                            width={14}
                          />
                        }
                      />
                    </div>
                  )}

                  {userData.pinnedItems?.includes('Tweet (X)') && (
                    <div className={styles['section']}>
                      <SidebarOptionHeader
                        isSelected={
                          selectedSidebarOption === FormType.PERSONA_TWITTER
                        }
                        text="Tweet"
                        href={'/editor/content?formType=tweet'}
                        DynamicIcon={
                          <Image
                            src="/icons/Twitter.svg"
                            alt="Tweet"
                            layout="fixed"
                            objectFit="contain"
                            height={14}
                            width={14}
                          />
                        }
                      />
                    </div>
                  )}

                  {userData.pinnedItems?.includes('LinkedIn Post') && (
                    <div className={styles['section']}>
                      <SidebarOptionHeader
                        isSelected={
                          selectedSidebarOption === FormType.PERSONA_LINKEDIN
                        }
                        text="LinkedIn Post"
                        href={'/editor/content?formType=linkedin%20post'}
                        DynamicIcon={
                          <Image
                            src="/icons/LinkedIn.svg"
                            alt="LinkedIn Post"
                            layout="fixed"
                            objectFit="contain"
                            height={14}
                            width={14}
                          />
                        }
                      />
                    </div>
                  )}

                  {userData.pinnedItems?.includes('Email') && (
                    <div className={styles['section']}>
                      <SidebarOptionHeader
                        isSelected={
                          selectedSidebarOption === FormType.EMAIL ||
                          selectedSidebarOption === FormType.PERSONAEMAIL
                        }
                        text="Email"
                        href={'/editor/content?formType=persona%20email'}
                        DynamicIcon={
                          <Image
                            src="/icons/Email_template.svg"
                            alt="Email"
                            layout="fixed"
                            objectFit="contain"
                            height={14}
                            width={14}
                          />
                        }
                      />
                    </div>
                  )}

                  {userData.pinnedItems?.includes('Short Blog') && (
                    <div className={styles['section']}>
                      <SidebarOptionHeader
                        isSelected={
                          selectedSidebarOption === FormType.BLOG ||
                          selectedSidebarOption === FormType.PERSONABLOG
                        }
                        text="Short Blog"
                        href={'/editor/content?formType=persona%20blog%20post'}
                        DynamicIcon={
                          <Image
                            src="/icons/ShortBlog_template.svg"
                            alt="Email"
                            layout="fixed"
                            objectFit="contain"
                            height={14}
                            width={14}
                          />
                        }
                      />
                    </div>
                  )}

                  {userData.pinnedItems?.includes('Website Header') && (
                    <div className={styles['section']}>
                      <SidebarOptionHeader
                        isSelected={
                          selectedSidebarOption === FormType.PERSONA_HERO_HEADER
                        }
                        text="Website Header"
                        href={
                          '/editor/website?formType=persona%20hero%20header'
                        }
                        DynamicIcon={
                          <Image
                            src="/icons/Star.svg"
                            alt="Website Header"
                            layout="fixed"
                            objectFit="contain"
                            height={14}
                            width={14}
                          />
                        }
                      />
                    </div>
                  )}

                  {userData.pinnedItems?.includes('Website Subheader') && (
                    <div className={styles['section']}>
                      <SidebarOptionHeader
                        isSelected={
                          selectedSidebarOption === FormType.PERSONA_SUBHEADER
                        }
                        text="Website Subheader"
                        href={
                          '/editor/website?formType=persona%20website%20subheader'
                        }
                        DynamicIcon={
                          <Image
                            src="/icons/Menu-left.svg"
                            alt="Website Subheader"
                            layout="fixed"
                            objectFit="contain"
                            height={14}
                            width={14}
                          />
                        }
                      />
                    </div>
                  )}

                  {userData.pinnedItems?.includes('Feature Description') && (
                    <div className={styles['section']}>
                      <SidebarOptionHeader
                        isSelected={
                          selectedSidebarOption ===
                          FormType.PERSONA_FEATURE_DESCRIPTION
                        }
                        text="Feature Description"
                        href={
                          '/editor/website?formType=persona%20feature%20description'
                        }
                        DynamicIcon={
                          <Image
                            src="/icons/Configure.svg"
                            alt="Feature Description"
                            layout="fixed"
                            objectFit="contain"
                            height={14}
                            width={14}
                          />
                        }
                      />
                    </div>
                  )}

                  {userData.pinnedItems?.includes('Call To Action') && (
                    <div className={styles['section']}>
                      <SidebarOptionHeader
                        isSelected={
                          selectedSidebarOption === FormType.CALL_TO_ACTION
                        }
                        text="Call To Action"
                        href={
                          '/editor/website?formType=persona%20call%20to%20action'
                        }
                        DynamicIcon={
                          <Image
                            src="/icons/Geo Locate.svg"
                            alt="Call To Action"
                            layout="fixed"
                            objectFit="contain"
                            height={14}
                            width={14}
                          />
                        }
                      />
                    </div>
                  )}

                  {userData.pinnedItems?.includes('Newsletter') && (
                    <div className={styles['section']}>
                      <SidebarOptionHeader
                        isSelected={
                          selectedSidebarOption === FormType.PERSONA_NEWSLETTER
                        }
                        text="Newsletter"
                        href={'/editor/content?formType=persona%20newsletter'}
                        DynamicIcon={
                          <Image
                            src="/icons/Bullhorn.svg"
                            alt="Newsletter"
                            layout="fixed"
                            objectFit="contain"
                            height={14}
                            width={14}
                          />
                        }
                      />
                    </div>
                  )}

                  {userData.pinnedItems?.includes('Promotional Newsletter') && (
                    <div className={styles['section']}>
                      <SidebarOptionHeader
                        isSelected={
                          selectedSidebarOption ===
                          FormType.PROMOTIONAL_NEWSLETTER
                        }
                        text="Promotional Newsletter"
                        href={
                          '/editor/content?formType=promotional%20newsletter'
                        }
                        DynamicIcon={
                          <Image
                            src="/icons/Bullhorn.svg"
                            alt="Promotional Newsletter"
                            layout="fixed"
                            objectFit="contain"
                            height={14}
                            width={14}
                          />
                        }
                      />
                    </div>
                  )}

                  {userData.pinnedItems?.includes(
                    'Product Update Newsletter'
                  ) && (
                    <div className={styles['section']}>
                      <SidebarOptionHeader
                        isSelected={
                          selectedSidebarOption ===
                          FormType.PRODUCT_UPDATE_NEWSLETTER
                        }
                        text="Product Update Newsletter"
                        href={
                          '/editor/content?formType=product%20update%20newsletter'
                        }
                        DynamicIcon={
                          <Image
                            src="/icons/Bullhorn.svg"
                            alt="Product Update Newsletter"
                            layout="fixed"
                            objectFit="contain"
                            height={14}
                            width={14}
                          />
                        }
                      />
                    </div>
                  )}

                  {userData.pinnedItems?.includes('Educational Newsletter') && (
                    <div className={styles['section']}>
                      <SidebarOptionHeader
                        isSelected={
                          selectedSidebarOption ===
                          FormType.EDUCATIONAL_NEWSLETTER
                        }
                        text="Educational Newsletter"
                        href={
                          '/editor/content?formType=educational%20newsletter'
                        }
                        DynamicIcon={
                          <Image
                            src="/icons/Bullhorn.svg"
                            alt="Educational Newsletter"
                            layout="fixed"
                            objectFit="contain"
                            height={14}
                            width={14}
                          />
                        }
                      />
                    </div>
                  )}

                  {userData.pinnedItems?.includes(
                    'Organizational Newsletter'
                  ) && (
                    <div className={styles['section']}>
                      <SidebarOptionHeader
                        isSelected={
                          selectedSidebarOption ===
                          FormType.ORGANIZATIONAL_NEWSLETTER
                        }
                        text="Organizational Newsletter"
                        href={
                          '/editor/content?formType=organizational%20newsletter'
                        }
                        DynamicIcon={
                          <Image
                            src="/icons/Bullhorn.svg"
                            alt="Organizational Newsletter"
                            layout="fixed"
                            objectFit="contain"
                            height={14}
                            width={14}
                          />
                        }
                      />
                    </div>
                  )}

                  {userData.pinnedItems?.includes('Audience Changer') && (
                    <div className={styles['section']}>
                      <SidebarOptionHeader
                        isSelected={
                          selectedSidebarOption === FormType.AUDIENCE_CHANGER
                        }
                        text="Audience Changer"
                        href={'/editor/toolkit?formType=audience%20changer'}
                        DynamicIcon={
                          <Image
                            src="/icons/audienceChanger.svg"
                            alt="Audience Changer"
                            layout="fixed"
                            objectFit="contain"
                            height={14}
                            width={14}
                          />
                        }
                      />
                    </div>
                  )}

                  {userData.pinnedItems?.includes('Format Changer') && (
                    <div className={styles['section']}>
                      <SidebarOptionHeader
                        isSelected={
                          selectedSidebarOption === FormType.FORMAT_CHANGER
                        }
                        text="Format Changer"
                        href={'/editor/toolkit?formType=format%20changer'}
                        DynamicIcon={
                          <Image
                            src="/icons/formatChanger.svg"
                            alt="Format Changer"
                            layout="fixed"
                            objectFit="contain"
                            height={14}
                            width={14}
                          />
                        }
                      />
                    </div>
                  )}

                  {userData.pinnedItems?.includes('Tone Changer') && (
                    <div className={styles['section']}>
                      <SidebarOptionHeader
                        isSelected={
                          selectedSidebarOption === FormType.TONE_CHANGER
                        }
                        text="Tone Changer"
                        href={'/editor/toolkit?formType=tone%20changer'}
                        DynamicIcon={
                          <Image
                            src="/icons/toneChanger.svg"
                            alt="Tone Changer"
                            layout="fixed"
                            objectFit="contain"
                            height={14}
                            width={14}
                          />
                        }
                      />
                    </div>
                  )}

                  {userData.pinnedItems?.includes(
                    'Seasonal/Holiday Promotion'
                  ) && (
                    <div className={styles['section']}>
                      <SidebarOptionHeader
                        isSelected={selectedSidebarOption === FormType.SEASONAL}
                        text="Seasonal/Holiday Promotion"
                        href={'/editor/content?formType=seasonal%20holiday'}
                        DynamicIcon={
                          <Image
                            src="/icons/Tropical Drinks.svg"
                            alt="Seasonal/Holiday Promotion"
                            layout="fixed"
                            objectFit="contain"
                            height={14}
                            width={14}
                          />
                        }
                      />
                    </div>
                  )}

                  {userData.pinnedItems?.includes('Discount Promotion') && (
                    <div className={styles['section']}>
                      <SidebarOptionHeader
                        isSelected={selectedSidebarOption === FormType.DISCOUNT}
                        text="Discount Promotion"
                        href={'/editor/content?formType=discount%20promotion'}
                        DynamicIcon={
                          <Image
                            src="/icons/Dollar.svg"
                            alt="Discount Promotion"
                            layout="fixed"
                            objectFit="contain"
                            height={14}
                            width={14}
                          />
                        }
                      />
                    </div>
                  )}

                  {userData.pinnedItems?.includes('Giveaway Promotion') && (
                    <div className={styles['section']}>
                      <SidebarOptionHeader
                        isSelected={selectedSidebarOption === FormType.GIVEAWAY}
                        text="Giveaway Promotion"
                        href={'/editor/content?formType=giveaway%20promotion'}
                        DynamicIcon={
                          <Image
                            src="/icons/Ticket.svg"
                            alt="Giveaway Promotion"
                            layout="fixed"
                            objectFit="contain"
                            height={14}
                            width={14}
                          />
                        }
                      />
                    </div>
                  )}

                  {userData.pinnedItems?.includes('LinkedIn Company Bio') && (
                    <div className={styles['section']}>
                      <SidebarOptionHeader
                        isSelected={
                          selectedSidebarOption ===
                          FormType.LINKEDIN_COMPANY_BIO
                        }
                        text="LinkedIn Company Bio"
                        href={
                          '/editor/content?formType=linkedin%20company%20bio'
                        }
                        DynamicIcon={
                          <Image
                            src="/icons/LinkedIn.svg"
                            alt="LinkedIn Company Bio"
                            layout="fixed"
                            objectFit="contain"
                            height={14}
                            width={14}
                          />
                        }
                      />
                    </div>
                  )}

                  {userData.pinnedItems?.includes(
                    'YouTube Channel Description'
                  ) && (
                    <div className={styles['section']}>
                      <SidebarOptionHeader
                        isSelected={
                          selectedSidebarOption ===
                          FormType.YOUTUBE_CHANNEL_DESCRIPTION
                        }
                        text="YouTube Channel Description"
                        href={
                          '/editor/content?formType=youtube%20channel%20description'
                        }
                        DynamicIcon={
                          <Image
                            src="/icons/YouTube.svg"
                            alt="YouTube Channel Description"
                            layout="fixed"
                            objectFit="contain"
                            height={14}
                            width={14}
                          />
                        }
                      />
                    </div>
                  )}

                  {userData.pinnedItems?.includes(
                    'YouTube Video Description'
                  ) && (
                    <div className={styles['section']}>
                      <SidebarOptionHeader
                        isSelected={
                          selectedSidebarOption ===
                          FormType.YOUTUBE_VIDEO_DESCRIPTION
                        }
                        text="YouTube Video Description"
                        href={
                          '/editor/content?formType=youtube%20video%20description'
                        }
                        DynamicIcon={
                          <Image
                            src="/icons/YouTube.svg"
                            alt="YouTube Video Description"
                            layout="fixed"
                            objectFit="contain"
                            height={14}
                            width={14}
                          />
                        }
                      />
                    </div>
                  )}

                  {userData.pinnedItems?.includes('YouTube Video Script') && (
                    <div className={styles['section']}>
                      <SidebarOptionHeader
                        isSelected={
                          selectedSidebarOption ===
                          FormType.YOUTUBE_VIDEO_SCRIPT
                        }
                        text="YouTube Video Script"
                        href={
                          '/editor/content?formType=youtube%20video%20script'
                        }
                        DynamicIcon={
                          <Image
                            src="/icons/YouTube.svg"
                            alt="YouTube Video Script"
                            layout="fixed"
                            objectFit="contain"
                            height={14}
                            width={14}
                          />
                        }
                      />
                    </div>
                  )}

                  {userData.pinnedItems?.includes(
                    'Short-Form Video Script'
                  ) && (
                    <div className={styles['section']}>
                      <SidebarOptionHeader
                        isSelected={
                          selectedSidebarOption === FormType.SHORT_VIDEO_SCRIPT
                        }
                        text="Short-Form Video Script"
                        href={
                          '/editor/content?formType=short-form%20video%20script'
                        }
                        DynamicIcon={
                          <Image
                            src="/icons/Scroll.svg"
                            alt="Short-Form Video Script"
                            layout="fixed"
                            objectFit="contain"
                            height={14}
                            width={14}
                          />
                        }
                      />
                    </div>
                  )}

                  {userData.pinnedItems?.includes('TikTok Caption') && (
                    <div className={styles['section']}>
                      <SidebarOptionHeader
                        isSelected={selectedSidebarOption === FormType.TIKTOK}
                        text="TikTok Caption"
                        href={'/editor/content?formType=tik%20tok%20caption'}
                        DynamicIcon={
                          <Image
                            src="/icons/Tiktok.svg"
                            alt="TikTok Caption"
                            layout="fixed"
                            objectFit="contain"
                            height={14}
                            width={14}
                          />
                        }
                      />
                    </div>
                  )}

                  {userData.pinnedItems?.includes('Product Description') && (
                    <div className={styles['section']}>
                      <SidebarOptionHeader
                        isSelected={
                          selectedSidebarOption === FormType.PRODUCT_DESCRIPTION
                        }
                        text="Product Description"
                        href={'/editor/toolkit?formType=product%20description'}
                        DynamicIcon={
                          <Image
                            src="/icons/Bike.svg"
                            alt="Product Description"
                            layout="fixed"
                            objectFit="contain"
                            height={14}
                            width={14}
                          />
                        }
                      />
                    </div>
                  )}

                  {userData.pinnedItems?.includes('Value Proposition') && (
                    <div className={styles['section']}>
                      <SidebarOptionHeader
                        isSelected={
                          selectedSidebarOption === FormType.VALUE_PROP
                        }
                        text="Value Proposition"
                        href={'/editor/toolkit?formType=value%20proposition'}
                        DynamicIcon={
                          <Image
                            src="/icons/Room Service Help.svg"
                            alt="Value Proposition"
                            layout="fixed"
                            objectFit="contain"
                            height={14}
                            width={14}
                          />
                        }
                      />
                    </div>
                  )}

                  {userData.pinnedItems?.includes('Campaign Brainstorm') && (
                    <div className={styles['section']}>
                      <SidebarOptionHeader
                        isSelected={
                          selectedSidebarOption === FormType.MARKETINGIDEAS
                        }
                        text="Campaign Brainstorm"
                        href={
                          '/editor/brainstorm?formType=persona%20marketing%20ideas'
                        }
                        DynamicIcon={
                          <Image
                            src="/icons/Weather Thunderstorms.svg"
                            alt="Campaign Brainstorm"
                            layout="fixed"
                            objectFit="contain"
                            height={14}
                            width={14}
                          />
                        }
                      />
                    </div>
                  )}

                  {userData.pinnedItems?.includes('Improve Copy') && (
                    <div className={styles['section']}>
                      <SidebarOptionHeader
                        isSelected={
                          selectedSidebarOption === FormType.CONTENT_IMPROVER
                        }
                        text="Improve Copy"
                        href={'/editor/toolkit?formType=improve%20copy'}
                        DynamicIcon={
                          <Image
                            src="/icons/Spaceship.svg"
                            alt="Improve Copy"
                            layout="fixed"
                            objectFit="contain"
                            height={14}
                            width={14}
                          />
                        }
                      />
                    </div>
                  )}

                  {userData.pinnedItems?.includes('Simplify Copy') && (
                    <div className={styles['section']}>
                      <SidebarOptionHeader
                        isSelected={
                          selectedSidebarOption === FormType.CONTENT_CONCISER
                        }
                        text="Simplify Copy"
                        href={'/editor/toolkit?formType=simplify%20copy'}
                        DynamicIcon={
                          <Image
                            src="/icons/Target.svg"
                            alt="Simplify Copy"
                            layout="fixed"
                            objectFit="contain"
                            height={14}
                            width={14}
                          />
                        }
                      />
                    </div>
                  )}

                  {userData.pinnedItems?.includes('Expand Copy') && (
                    <div className={styles['section']}>
                      <SidebarOptionHeader
                        isSelected={
                          selectedSidebarOption === FormType.CONTENT_EXPANDER
                        }
                        text="Expand Copy"
                        href={'/editor/toolkit?formType=expand%20copy'}
                        DynamicIcon={
                          <Image
                            src="/icons/Full Screen Resize Expand.svg"
                            alt="Expand Copy"
                            layout="fixed"
                            objectFit="contain"
                            height={14}
                            width={14}
                          />
                        }
                      />
                    </div>
                  )}

                  {userData.pinnedItems?.includes('Brand Mission') && (
                    <div className={styles['section']}>
                      <SidebarOptionHeader
                        isSelected={
                          selectedSidebarOption === FormType.BRAND_MISSION
                        }
                        text="Brand Mission"
                        href={'/editor/toolkit?formType=brand%20mission'}
                        DynamicIcon={
                          <Image
                            src="/icons/Bio Fingerprint.svg"
                            alt="Brand Mission"
                            layout="fixed"
                            objectFit="contain"
                            height={14}
                            width={14}
                          />
                        }
                      />
                    </div>
                  )}

                  {userData.pinnedItems?.includes('Job Description') && (
                    <div className={styles['section']}>
                      <SidebarOptionHeader
                        isSelected={
                          selectedSidebarOption === FormType.JOB_DESCRIPTION
                        }
                        text="Job Description"
                        href={'/editor/content?formType=job%20description'}
                        DynamicIcon={
                          <Image
                            src="/icons/Bio Fingerprint.svg"
                            alt="Job Description"
                            layout="fixed"
                            objectFit="contain"
                            height={14}
                            width={14}
                          />
                        }
                      />
                    </div>
                  )}

                  {userData.pinnedItems?.includes('Meta Ads') && (
                    <div className={styles['section']}>
                      <SidebarOptionHeader
                        isSelected={selectedSidebarOption === FormType.META_ADS}
                        text="Meta Ads"
                        href={'/editor/content?formType=meta%20ads'}
                        DynamicIcon={
                          <Image
                            src="/icons/Star.svg"
                            alt="Meta Ads"
                            layout="fixed"
                            objectFit="contain"
                            height={14}
                            width={14}
                          />
                        }
                      />
                    </div>
                  )}

                  {userData.pinnedItems?.includes('Google Ads') && (
                    <div className={styles['section']}>
                      <SidebarOptionHeader
                        isSelected={
                          selectedSidebarOption === FormType.GOOGLE_ADS
                        }
                        text="Google Ads"
                        href={'/editor/content?formType=google%20ads'}
                        DynamicIcon={
                          <Image
                            src="/icons/Google.svg"
                            alt="Google Ads"
                            layout="fixed"
                            objectFit="contain"
                            height={14}
                            width={14}
                          />
                        }
                      />
                    </div>
                  )}

                  {/*  */}
                </div>
              </div>
            )}

            <div className={styles['section-end']}>
              {/* <div className={styles['ending-divider']}></div> */}

              <Link href="/settings">
                <a className={styles['section']}>
                  <h2>
                    <Image
                      src="/icons/Settings.svg"
                      alt="Close"
                      layout="fixed"
                      objectFit="contain"
                      height={12}
                      width={12}
                    />
                    Settings
                  </h2>
                  {numInvitesRemaining > 0 && (
                    <SimpleTag
                      text={`${numInvitesRemaining}`}
                      backgroundColor="var(--ea-magenta)"
                      border="none"
                      color="var(--ea-white)"
                    />
                  )}
                </a>
              </Link>
            </div>
          </nav>
        )}
      {((superChatSideNav && userData && !userData.featureLimitAccess) ||
        (superChatSideNav && userData && userData.limitFeatureName === '')) && (
        <nav
          className={styles['navbar-container2']}
          style={{
            display: showSidebarInMobile || isInDesktopMode ? '' : 'none',
          }}
        >
          <Link href="/dashboard">
            <h2 className={styles['name-container2']}>
              <Image
                src="/icons/NEW_SC_ICON.png"
                alt="Close"
                layout="fixed"
                objectFit="contain"
                height={30}
                width={30}
              />
            </h2>
          </Link>
          {/* <div className={styles['divider']}></div> */}
          <div className={styles['section']}>
            <SidebarOptionHeader
              isSelected={router.route.includes('dashboard')}
              href={'/dashboard'}
              DynamicIcon={
                <HomeMinimalSvg
                  stroke={
                    router.route.includes('dashboard')
                      ? 'var(--ea-black)'
                      : 'var(--ea-black)'
                  }
                />
              }
              nameless={true}
            />
          </div>

          <div className={styles['section']}>
            <SidebarOptionHeader
              isSelected={router.route.includes('campaign')}
              href={'/campaigns'}
              DynamicIcon={<CampaignsSVG />}
              nameless={true}
            />
          </div>

          <div className={styles['section']}>
            <SidebarOptionHeader
              isSelected={router.route.includes('personas')}
              href={'/personas'}
              DynamicIcon={<PersonasSVG />}
              nameless={true}
              // simpleBadge={
              //   <SimpleBadge text='Beta' />
              // }
            />
          </div>

          {userData?.position !== 'Can View' && (
            <div className={styles['section']}>
              <SidebarOptionHeader
                isSelected={router.route.includes('superChat')}
                href={'/superChat'}
                DynamicIcon={<SuperChatSVG />}
                nameless={true}
              />
            </div>
          )}

          {userData?.position !== 'Can View' && (
            <div className={styles['section']}>
              <SidebarOptionHeader
                isSelected={router.route.includes('templates')}
                href={'/templates'}
                DynamicIcon={<TemplatesSVG />}
                nameless={true}
              />
            </div>
          )}

          <div className={styles['section4']}>
            <SidebarOptionHeader
              isSelected={router.route.includes('saved')}
              href={'/saved'}
              DynamicIcon={<ContentSVG />}
              nameless={true}
            />
          </div>
          <div className={styles['section-end']}>
            {/* <div className={styles['ending-divider']}></div> */}

            <Link href="/settings">
              <a className={styles['section2']}>
                <h2>
                  <Image
                    src="/icons/Settings.svg"
                    alt="Close"
                    layout="fixed"
                    objectFit="contain"
                    height={12}
                    width={12}
                  />
                </h2>
                {/* {numInvitesRemaining > 0 && (
                  <SimpleTag
                    text={`${numInvitesRemaining}`}
                    backgroundColor="var(--ea-magenta)"
                    border="none"
                    color="var(--ea-white)"
                  />
                )} */}
              </a>
            </Link>
          </div>
        </nav>
      )}
      {superChatSideNav &&
        userData?.featureLimitAccess === true &&
        userData.limitFeatureName &&
        userData?.limitFeatureName.length > 0 && (
          <nav
            className={styles['navbar-container2']}
            style={{
              display: showSidebarInMobile || isInDesktopMode ? '' : 'none',
            }}
          >
            <Link href="/dashboard">
              <h2 className={styles['name-container2']}>
                <Image
                  src="/icons/NEW_SC_ICON.png"
                  alt="Close"
                  layout="fixed"
                  objectFit="contain"
                  height={30}
                  width={30}
                />
              </h2>
            </Link>
            {/* <div className={styles['divider']}></div> */}
            <div className={styles['section']}>
              <SidebarOptionHeader
                isSelected={router.route.includes('dashboard')}
                href={'/dashboard'}
                DynamicIcon={
                  <HomeMinimalSvg
                    stroke={
                      router.route.includes('dashboard')
                        ? 'var(--ea-black)'
                        : 'var(--ea-black)'
                    }
                  />
                }
                nameless={true}
              />
            </div>

            {userData.allowedFeatures?.includes('Campaigns') && (
              <div className={styles['section']}>
                <SidebarOptionHeader
                  isSelected={router.route.includes('campaign')}
                  href={'/campaigns'}
                  DynamicIcon={<CampaignsSVG />}
                  nameless={true}
                />
              </div>
            )}
            {userData.allowedFeatures?.includes('Persona Library') && (
              <div className={styles['section']}>
                <SidebarOptionHeader
                  isSelected={router.route.includes('personas')}
                  href={'/personas'}
                  DynamicIcon={<PersonasSVG />}
                  nameless={true}
                  // simpleBadge={
                  //   <SimpleBadge text='Beta' />
                  // }
                />
              </div>
            )}

            {userData.allowedFeatures?.includes('SuperChat') &&
              userData?.position !== 'Can View' && (
                <div className={styles['section']}>
                  <SidebarOptionHeader
                    isSelected={router.route.includes('superChat')}
                    href={'/superChat'}
                    DynamicIcon={<SuperChatSVG />}
                    nameless={true}
                  />
                </div>
              )}

            {userData.allowedFeatures?.includes('Templates') &&
              userData?.position !== 'Can View' && (
                <div className={styles['section']}>
                  <SidebarOptionHeader
                    isSelected={router.route.includes('templates')}
                    href={'/templates'}
                    DynamicIcon={<TemplatesSVG />}
                    nameless={true}
                  />
                </div>
              )}

            <div className={styles['section4']}>
              <SidebarOptionHeader
                isSelected={router.route.includes('saved')}
                href={'/saved'}
                DynamicIcon={<ContentSVG />}
                nameless={true}
              />
            </div>
            <div className={styles['section-end']}>
              {/* <div className={styles['ending-divider']}></div> */}

              <Link href="/settings">
                <a className={styles['section2']}>
                  <h2>
                    <Image
                      src="/icons/Settings.svg"
                      alt="Close"
                      layout="fixed"
                      objectFit="contain"
                      height={12}
                      width={12}
                    />
                  </h2>
                  {/* {numInvitesRemaining > 0 && (
                    <SimpleTag
                      text={`${numInvitesRemaining}`}
                      backgroundColor="var(--ea-magenta)"
                      border="none"
                      color="var(--ea-white)"
                    />
                  )} */}
                </a>
              </Link>
            </div>
          </nav>
        )}
      <div className={styles['content-container']}>
        {/* {isLoading &&(
           <div 
           className={styles['loading-overlay']} 
           style={{
           opacity: isLoading ? 1 : 0,
           visibility: isDisplayed ? 'visible' : 'hidden'
            }}>
              <div className={styles['lightning-bolt']} >
              <Image
                      src={'/demoGifs/clearLoad3.gif'}
                      alt="Close"
                      layout="fixed"
                      objectFit="contain"
                      height={220}
                      width={220}
                    />
              </div>
           </div>
        )} */}
        {showOnboardingLoadScreen && (
          <div className={styles['loading-container']}>
            <h1>Welcome to SuperCopy!</h1>
            <Image
              src="/demoGifs/animation.gif"
              alt=""
              objectFit="contain"
              height={25}
              width={50}
            />
            <h2>⚡ Preparing your onboarding process.</h2>
          </div>
        )}
        {unsubscribed && (
          <div className={styles['popup']}>
            <BasicPopup id="persona-popup">
              <div className={styles['popup-container']}>
                <div className={styles['popup-header-container']}>
                  <h3>You are currently unsubscribed.</h3>
                </div>
                <div className={styles['TrialText2']}>
                  <div>
                    <b>
                      We hate goodbyes, and we'd love for you to stick around!
                      In order to keep generating content and access your
                      personas, you will need to upgrade or reactivate your
                      account to a paid subscription.
                    </b>
                  </div>
                </div>
                <div className={styles['TrialButtons']}>
                  <BasicButton
                    theme="dark"
                    type="button"
                    loading={false}
                    disabled={false}
                    text="Schedule a demo"
                    onClick={() => {
                      router.push('https://calendly.com/supercopy/30min');
                    }}
                    extraStyles={
                      {
                        // width: '150%',
                      }
                    }
                  />{' '}
                  <span>&nbsp;</span> <span>&nbsp;</span>
                  <BasicButton
                    theme="blue"
                    type="button"
                    loading={false}
                    disabled={false}
                    text="See Options"
                    image={{
                      src: '/icons/Emoji Happy Smile.svg',
                      height: 16,
                      width: 16,
                    }}
                    onClick={async () => {
                      await router.push('/afterTrialPricing');
                    }}
                    extraStyles={
                      {
                        // width: '150%',
                      }
                    }
                  />{' '}
                  <span>&nbsp;</span> <span>&nbsp;</span>
                  <BasicButton
                    theme="dark"
                    type="button"
                    loading={false}
                    disabled={false}
                    text="Sign out"
                    onClick={() => {
                      signOutUser();
                    }}
                    extraStyles={
                      {
                        // width: '150%',
                      }
                    }
                  />
                </div>
              </div>
            </BasicPopup>
          </div>
        )}
        <div className={styles['top-navbar']}>
          <div className={styles['mobile-navbar-container']}>
            <Link href="/">
              <h2>
                <Image
                  src="/images/SuperCopy_Logo.svg"
                  alt="Close"
                  layout="fixed"
                  objectFit="contain"
                  height={60}
                  width={120}
                />
              </h2>
            </Link>
            <button
              onClick={() => setShowSidebarInMobile(!showSidebarInMobile)}
              className={styles['menu-button']}
            >
              <p>
                <b>{showSidebarInMobile ? 'Close' : 'Menu'}</b>
              </p>
            </button>
          </div>
          <div className={styles['trial-title']}>
            {showTrialBar && (
              <div className={styles['trial']}>
                <h2>
                  <span className={styles['trialBar']}>
                    🎉 Free trial:{' '}
                    <span className={styles['trialNumber']}>{trialDays}</span>{' '}
                    days left
                  </span>
                </h2>
              </div>
            )}
            {!hideTitle && (
              <div className={styles['desktop-title']}>
                <h2>{selectedSidebarOption}</h2>
              </div>
            )}
          </div>
        </div>
        {children}
      </div>
    </div>
  );
};
